import { Client } from '@stomp/stompjs';
import env from './AppEnv';
import log from './Logger';
const RECONNECT_TIMEOUT = 3_000;
let stomp;
let initialized = false;
env.user.authenticated && initialize();
function initialize() {
    if (initialized) {
        return;
    }
    const url = new URL(`${env.path}/stomp`, document.baseURI);
    url.protocol = 'wss';
    stomp = new Client({
        brokerURL: url.toString(),
        onConnect: onConnect,
        reconnectDelay: RECONNECT_TIMEOUT,
        debug: log,
    });
    stomp.activate();
    initialized = true;
}
function onConnect() {
    log('WS connection established');
    subscriptionCallbacks.forEach((value, key) => {
        value.sub = stomp.subscribe(key, value.callback);
    });
}
const subscriptionCallbacks = new Map();
const palomaWebsocket = {
    subscribe(destination, callback) {
        if (!initialized) {
            throw 'WS not initialized';
        }
        if (subscriptionCallbacks.has(destination)) {
            throw 'Duplicate subscription for ' + destination;
        }
        log(`WS subscription to ${destination}`);
        if (destination.indexOf('/queue/') !== 0 && destination.indexOf('/user/queue/') !== 0) {
            log('ATTENTION: WS destination should start with \'/queue/\' or \'/user/queue/\'.');
        }
        const me = { callback: callback };
        subscriptionCallbacks.set(destination, me);
        if (stomp.connected) {
            me.sub = stomp.subscribe(destination, callback);
        }
        // if stomp is not connected yet, `subscribe` will be called for all
        // `subscriptionCallbacks` when connected (in `onConnect`).
        return {
            unsubscribe() {
                const sub = subscriptionCallbacks.get(destination);
                if (!sub) {
                    throw 'No subscription found, did you call unsubscribe twice?';
                }
                log(`WS unsubscribe from ${destination}`, stomp.connected);
                subscriptionCallbacks.delete(destination);
                if (stomp.connected) {
                    me.sub.unsubscribe();
                }
            }
        };
    }
};
export default palomaWebsocket;
