import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import trans from '@astina/paloma-common-ui/src/services/Translator';
export function deleteWatchlist(watchlistId) {
    return new DeleteWatchlist(watchlistId)
        .enqueue();
}
export default class DeleteWatchlist extends AbstractCommand {
    watchlistId;
    constructor(watchlistId) {
        super();
        this.watchlistId = watchlistId;
    }
    execute() {
        return paloma
            .execute(this.name(), {
            watchlistId: this.watchlistId,
        })
            .then(() => {
            return this.watchlistId;
        })
            .catch((error) => {
            alerts.error(trans(`command.${this.name()}.error`));
            throw error;
        });
    }
    needsConfirmation() {
        return true;
    }
    isDangerous() {
        return true;
    }
    requiresPermission() {
        return 'PORTAL_WATCHLISTS_MANAGE';
    }
    name() {
        return 'delete_watchlist';
    }
    icon() {
        return 'delete';
    }
}
