import palomaBackend from "@astina/paloma-common-ui/src/services/PalomaBackend";
import { handleHttpError } from "@astina/paloma-common-ui/src/services/Alerts";
const http = palomaBackend.http;
class ConfigRepository {
    config = null;
    getConfig() {
        if (this.config) {
            return this.config;
        }
        this.config = http
            .get(`orders/config/orders_ui`)
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
        return this.config;
    }
}
export default new ConfigRepository();
