import paloma from "@astina/paloma-common-ui/src/services/PalomaBackend";
import env from '@astina/paloma-common-ui/src/services/AppEnv';
import trans from '@astina/paloma-common-ui/src/services/Translator';
import { handleHttpError } from "@astina/paloma-common-ui/src/services/Alerts";
const http = paloma.http;
class CustomerRepository {
    getDeliveryLocationForm() {
        return http
            .get('customers/get_delivery_location_form', {
            params: {
                locale: env.locale,
            }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getUserGridConfig() {
        return http
            .get('customers/get_users_grid_config', {
            params: {
                locale: env.locale,
            }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    listCustomers() {
        return http
            .get('customers/list_customers', {
            params: {
                locale: env.locale,
            },
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getCustomer() {
        return http
            .get('customers/get_customer', {
            params: {
                locale: env.locale,
            },
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    listUsers(searchRequest, page, attributes) {
        return http
            .get('customers/list_users', {
            params: {
                locale: env.locale,
            },
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getUser(id) {
        return http
            .get('customers/get_user', {
            params: {
                id: id,
                locale: env.locale,
            },
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getUserForm() {
        return http
            .get('customers/get_user_form', {
            params: {
                locale: env.locale,
            },
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getUsersGridItemsSource() {
        return {
            get: this.listUsers
        };
    }
    listWatchlists() {
        return http
            .get('customers/list_watchlists', {
            params: {
                locale: env.locale,
            },
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getWatchlist(id, itemOffset, itemLimit) {
        return http
            .get('customers/get_watchlist', {
            params: {
                id: id,
                locale: env.locale,
                itemOffset,
                itemLimit,
            },
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getWatchlistByAccessToken(accessToken) {
        return http
            .get('customers/get_watchlist_by_access_token', {
            params: {
                accessToken: accessToken,
                locale: env.locale,
            },
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getWatchlistCreateForm() {
        return Promise.resolve({
            name: 'watchlist',
            fields: [{
                    name: 'name',
                    label: trans('watchlist.name'),
                    type: 'text',
                    options: {
                        required: true,
                    },
                }],
        });
    }
    getWatchlistEditForm() {
        return this.getWatchlistCreateForm();
    }
    getWatchlistShareForm() {
        return Promise.resolve({
            name: 'watchlist_share',
            fields: [
                {
                    name: 'publicName',
                    label: trans('watchlist.public_name'),
                    type: 'text',
                    options: {
                        required: true,
                    },
                },
                {
                    name: 'enabled',
                    label: trans('watchlist.share_enable'),
                    type: 'bool',
                    options: {
                        required: true,
                    },
                },
            ],
        });
    }
    getDeliveryDaysAfter(channel, customerId, date) {
        return http
            .get('customers/get_delivery_days', {
            params: {
                channel: channel,
                locale: env.locale,
                customerId: customerId,
                after: date != null ? date : null
            },
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
}
export default new CustomerRepository();
