import log from "./Logger";
export default function userSettings(settingsId) {
    return {
        get(defaults) {
            log('loading user settings', settingsId);
            const rawValue = window.localStorage.getItem(settingsId);
            if (!rawValue) {
                return defaults;
            }
            try {
                return JSON.parse(rawValue);
            }
            catch (e) {
                return defaults;
            }
        },
        set(data) {
            log('updating user settings', settingsId, data);
            if (!data) {
                window.localStorage.removeItem(settingsId);
                return;
            }
            window.localStorage.setItem(settingsId, JSON.stringify(data));
        }
    };
}
