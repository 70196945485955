export default class WatchlistUtils {
    static permitted(watchlist, operations) {
        if (!watchlist) {
            return false;
        }
        for (const operation of operations) {
            if (watchlist._operations
                && Object.prototype.hasOwnProperty.call(watchlist._operations, operation)
                // @ts-ignore
                && watchlist._operations[operation].permitted) {
                return true;
            }
        }
        return false;
    }
    static createOrderingUnitForWatchlistItem(item) {
        return {
            unit: item.unit,
            unitCode: item.unitCode,
            unitDecimals: 0, // TODO
            price: {
                currency: '',
                unitPrice: '',
                unitDecimals: 0, // TODO
                unit: item.unit,
                unitCode: item.unitCode,
                taxIncluded: false, // TODO
            },
        };
    }
    static createQuantityConfig(initial, orderingUnit, portions) {
        return {
            initial: Math.min(initial, 1000),
            unit: orderingUnit.unitCode,
            min: 0,
            max: 1000,
            decimals: orderingUnit.unitDecimals || 0,
            portioning: orderingUnit.portioning,
            portioningUnit: orderingUnit.portioningUnit,
            portioningNumerator: orderingUnit.portioningNumerator,
            portioningDenominator: orderingUnit.portioningDenominator,
            portions: portions,
        };
    }
}
