import serviceLocator from "./ServiceLocator";
import palomaBackend from "./PalomaBackend";
import { handleHttpError } from "./Alerts";
import palomaWebsocket from "./WebSocket";
const http = palomaBackend.http;
class PalomaLogService {
    list(searchRequest, page) {
        return http.post('logs/logs/search', searchRequest, {
            params: {
                page: page.number,
                size: page.size,
                sort: page.sort
            }
        })
            .then((response) => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    stream(trace, onUpdate) {
        const subscription = palomaWebsocket.subscribe('/queue/log/' + trace, (message) => {
            const statement = JSON.parse(message.body);
            onUpdate([statement]);
        });
        return {
            disconnect() {
                subscription.unsubscribe();
            }
        };
    }
}
export function initPalomaLogService() {
    serviceLocator.set('log_service', new PalomaLogService());
}
