import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import env from '../services/AppEnv';
export function exportWatchlist(watchlistId) {
    return new ExportWatchlist(watchlistId)
        .enqueue();
}
export default class ExportWatchlist extends AbstractCommand {
    watchlistId;
    constructor(watchlistId) {
        super();
        this.watchlistId = watchlistId;
    }
    execute() {
        const url = `${env.path}/export/watchlists/${this.watchlistId}.xlsx`;
        window.open(url, '_blank');
        return Promise.resolve();
    }
    name() {
        return 'export_watchlist';
    }
    icon() {
        return 'file-excel';
    }
    requiresPermission() {
        return 'PORTAL_WATCHLISTS_VIEW';
    }
}
