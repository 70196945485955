/**
 * @param subject object to filter
 * @param query filter query string
 * @param props optional: fields to search in
 */
export function filterObject(subject, query, props) {
    if (!query) {
        return true;
    }
    if (!subject) {
        return false;
    }
    const tokens = query.split(" ");
    let matchedTokens = 0;
    const properties = props || Object.keys(subject);
    for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i];
        for (let j = 0; j < properties.length; j++) {
            const property = properties[j];
            if (filterString(subject[property], token)) {
                matchedTokens++;
                break;
            }
        }
    }
    return matchedTokens === tokens.length;
}
export function filterString(subject, query) {
    if (!query || !subject) {
        return true;
    }
    const tokens = query.split(" ");
    if (tokens.length === 1) {
        return subject.toLowerCase().indexOf(query) !== -1;
    }
    let matchedTokens = 0;
    for (let i = 0; i < tokens.length; i++) {
        if (filterString(subject, tokens[i])) {
            matchedTokens++;
        }
    }
    return matchedTokens === tokens.length;
}
export function searchFilterOperatorTakesValue(operator) {
    return operator !== 'has_value'
        && operator !== 'is_missing'
        && operator !== 'is_empty'
        && operator !== 'not_empty';
}
