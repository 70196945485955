import env from '../services/AppEnv';
import { isGranted } from "../services/AccessManager";
import log from "../services/Logger";
import trans from "../services/Translator";
import formUtils from "../components/form/FormUtils";
const checkPermission = (to, from, next) => {
    const nearestWithPermission = to.matched.slice()
        .reverse()
        .find(r => r.meta && r.meta.permission);
    if (nearestWithPermission && !isGranted(nearestWithPermission.meta['permission'])) {
        next('');
        return;
    }
    next();
};
const checkDirtyForms = (to, from, next) => {
    if (!formUtils.confirmDirtyForms()) {
        next(false);
        return;
    }
    next();
};
const updatePageTitle = (to, from, next) => {
    try {
        const nearestWithTitle = to.matched.slice()
            .reverse()
            .find(r => r.meta && r.meta.title);
        setPageTitle(nearestWithTitle ? trans(nearestWithTitle.meta['title']) : null);
    }
    catch (e) {
        log(e);
    }
    next();
};
const setPageTitle = (title) => {
    const defaultTitle = env.app.title;
    document.title = `${title || ''}${title ? ' - ' : ''}${defaultTitle}`;
};
function singleParam(route, key) {
    const val = route.params[key];
    return Array.isArray(val) ? val[0] : val;
}
export default {
    updatePageTitle: updatePageTitle,
    setPageTitle: setPageTitle,
    checkPermission: checkPermission,
    checkDirtyForms: checkDirtyForms,
    singleParam: singleParam
};
