const defaults = {
    path: './',
    locale: 'de',
    version: '0.0.0',
    app: {
        name: 'Paloma',
        title: 'Paloma',
    },
    security: {
        anonymousAccessEnabled: false,
    },
    user: {
        authenticated: false,
        username: 'anonymous',
        permissions: [],
    },
    translations: {
        baseUrl: '/messages',
        locales: ['de'],
    },
    files: {
        baseUrl: '/files'
    },
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    importExportProfileApplication: 'data', // deprecated
    importProfileApplication: 'data',
    export: ['data'],
};
// @ts-ignore
const env = { ...defaults, ...window._ENV };
export default env;
