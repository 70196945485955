import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import shopIntegrationsRepository from '../services/ShopIntegrationsRepository';
export function enableDisableShopIntegration(integration, enabled) {
    return new EnableDisableShopIntegration(integration, enabled).enqueue();
}
export default class EnableDisableShopIntegration extends AbstractCommand {
    integration;
    enabled;
    constructor(integration, enabled) {
        super();
        this.integration = integration;
        this.enabled = enabled;
    }
    execute() {
        return this.enabled
            ? shopIntegrationsRepository.enable(this.integration)
            : shopIntegrationsRepository.disable(this.integration);
    }
    name() {
        return "enable_disable_shop_integration";
    }
}
