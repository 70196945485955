import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
export function setCartComment(cartId, comment) {
    return new SetCartComment(cartId, comment)
        .enqueue();
}
export default class SetCartComment extends AbstractCommand {
    cartId;
    comment;
    constructor(cartId, comment) {
        super();
        this.cartId = cartId;
        this.comment = comment;
    }
    execute() {
        return paloma
            .execute(this.name(), {
            cartId: this.cartId,
            comment: this.comment,
        });
    }
    name() {
        return 'set_cart_comment';
    }
    requiresPermission() {
        return 'PORTAL_ORDERS_CREATE';
    }
}
