import env from "../services/AppEnv";
import WatchlistSidebar from "../components/WatchlistSidebar.vue";
import ShoppingCartSidebar from "../components/ShoppingCartSidebar.vue";
import { EventBus } from "@astina/paloma-common-ui/src/services/EventBus";
export const SIDEBAR_STATE_CHANGED = 'sidebar-context.state-changed';
class AppSidebarContext extends EventBus {
    component = null;
    sidebarParams = {};
    toggleActiveWatchlist() {
        const watchlistId = env.orders.activeWatchlist;
        if (!watchlistId || this.isWatchlistSidebarOpen()) {
            this.reset();
        }
        else {
            this.component = WatchlistSidebar;
            this.$emit(SIDEBAR_STATE_CHANGED);
        }
    }
    toggleActiveCart() {
        const cartId = env.orders.activeDraft;
        if (!cartId || this.isCartSidebarOpen()) {
            this.reset();
        }
        else {
            this.component = ShoppingCartSidebar;
            this.sidebarParams = {};
            this.$emit(SIDEBAR_STATE_CHANGED);
        }
    }
    openActiveWatchlist(params) {
        this.sidebarParams = params || {};
        if (this.isWatchlistSidebarOpen()) {
            return;
        }
        this.toggleActiveWatchlist();
    }
    openActiveCart() {
        if (this.isCartSidebarOpen()) {
            return;
        }
        this.toggleActiveCart();
    }
    closeWatchlistSidebar() {
        if (this.isWatchlistSidebarOpen()) {
            this.reset();
        }
    }
    closeCartSidebar() {
        if (this.isCartSidebarOpen()) {
            this.reset();
        }
    }
    isOpen() {
        return this.component !== null;
    }
    isWatchlistSidebarOpen() {
        return this.component === WatchlistSidebar;
    }
    isCartSidebarOpen() {
        return this.component === ShoppingCartSidebar;
    }
    reset() {
        this.component = null;
        this.sidebarParams = {};
        this.$emit(SIDEBAR_STATE_CHANGED);
    }
}
export default new AppSidebarContext();
