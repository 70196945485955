import serviceLocator from "./ServiceLocator";
import { handleHttpError } from "./Alerts";
import processReportRepository from './ProcessReportRepository';
import palomaBackend from "./PalomaBackend";
import palomaWebsocket, {} from "./WebSocket";
import { throttle } from 'lodash';
const http = palomaBackend.http;
class PalomaProcessService {
    callOperation(report, operation) {
        return PalomaProcessService.executeOperation({
            application: report.trigger.application,
            handler: report.trigger.handler,
            process: report.process,
            id: report.id,
            operation: operation.type,
            params: operation.params,
        });
    }
    callStageOperation(report, stage, operation) {
        return PalomaProcessService.executeOperation({
            application: report.trigger.application,
            handler: report.trigger.handler,
            process: report.process,
            id: report.id,
            stage: stage.name,
            operation: operation.type,
            params: operation.params,
        });
    }
    static executeOperation(data) {
        return http
            .post('/processes/operations/execute', data)
            .then((response) => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    get(handler, process, id) {
        return processReportRepository.get(handler, process, id);
    }
    subscribe(report, onUpdate) {
        const onUpdateThrottled = throttle(onUpdate, 1000);
        return palomaWebsocket.subscribe(`/queue/process-reports/${report.trigger.handler}/${report.process}/${report.id}`, (message) => {
            const report = JSON.parse(message.body);
            onUpdateThrottled(report);
        });
    }
}
export function initPalomaProcessService() {
    serviceLocator.set('process_service', new PalomaProcessService());
}
