import palomaBackend from "@astina/paloma-common-ui/src/services/PalomaBackend";
import env from '../services/AppEnv';
import { handleHttpError } from "@astina/paloma-common-ui/src/services/Alerts";
const http = palomaBackend.http;
class OrderRepository {
    list(searchRequest, page, attributes) {
        return http
            .post('orders/list_orders', searchRequest, {
            params: {
                locale: env.locale,
                page: page.number,
                size: page.size,
                sort: page.sort && (page.sort + (page.order ? ',' + page.order : null)),
                attributes: attributes.join(','),
            }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    get(orderNumber) {
        return http
            .get('orders/get_order', {
            params: {
                orderNumber: orderNumber,
                locale: env.locale,
            }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getGridConfig() {
        return http
            .get('orders/get_grid_config', {
            params: { locale: env.locale }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getGridItemsSource() {
        return {
            get: this.list
        };
    }
}
export default new OrderRepository();
