import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import alerts from "@astina/paloma-common-ui/src/services/Alerts";
import translate from "@astina/paloma-common-ui/src/services/Translator";
import ValidationUtils from "@astina/paloma-common-ui/src/services/ValidationUtils";
import { isGranted } from "@astina/paloma-common-ui/src/services/AccessManager";
export default class PurchaseOrder extends AbstractCommand {
    cartId;
    constructor(cartId) {
        super();
        this.cartId = cartId;
    }
    execute() {
        if (this.requiresPermission() && !isGranted(this.requiresPermission())) {
            alerts.error(translate('app.security_error.access_denied'));
            return Promise.reject();
        }
        return paloma
            .execute(this.name(), {
            cartId: this.cartId
        })
            .catch((error) => {
            try {
                const validation = ValidationUtils.createValidation(error);
                ValidationUtils.alertAll(validation);
            }
            catch (e) {
                alerts.error(translate('cart.purchase.error'));
            }
            throw error;
        });
    }
    name() {
        return 'purchase_order';
    }
    icon() {
        return 'paper-plane';
    }
    needsConfirmation() {
        return true;
    }
    requiresPermission() {
        return 'PORTAL_ORDERS_PURCHASE';
    }
}
