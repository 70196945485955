import env from './AppEnv';
/**
 * Returns true if the current user has at least one of the
 * given permissions assigned.
 */
function isGranted(...permissions) {
    return isAnyGranted(permissions);
}
function isAnyGranted(permissions) {
    for (const permission of permissions) {
        const authority = permission.indexOf('ROLE_PERM_') !== 0
            ? `ROLE_PERM_${permission}`
            : permission;
        if (env.user.permissions.indexOf(authority) > -1) {
            return true;
        }
    }
    return false;
}
export { isGranted, isAnyGranted, };
