import config from './services/PortalConfig';
import LoginView from "./views/LoginView.vue";
import LoginFormView from "./views/LoginFormView.vue";
import LoginResetPasswordView from "./views/LoginResetPasswordView.vue";
import LoginAccessView from "./views/LoginAccessView.vue";
import HomeView from "./views/HomeView.vue";
import CatalogView from "./views/CatalogView.vue";
import CatalogCategoryView from "./views/CatalogCategoryView.vue";
import ProductView from "./views/ProductView.vue";
import ProductDetailsView from "./views/ProductDetailsView.vue";
import ProductVariantView from "./views/ProductVariantView.vue";
import OrdersView from "./views/OrdersView.vue";
import OrderView from "./views/OrderView.vue";
import CartView from "./views/CartView.vue";
import DeliveriesView from "./views/DeliveriesView.vue";
import DeliveryView from "./views/DeliveryView.vue";
import IntegrationView from "./views/IntegrationView.vue";
import ExportProfilesView from "./views/ExportProfilesView.vue";
import ExportProfileCreateView from "./views/ExportProfileCreateView.vue";
import ExportProfileDetailView from "./views/ExportProfileDetailView.vue";
import ExportProfileEditView from "./views/ExportProfileEditView.vue";
import ImportProfilesView from "./views/ImportProfilesView.vue";
import ImportProfileCreateView from "./views/ImportProfileCreateView.vue";
import ImportProfileDetailView from "./views/ImportProfileDetailView.vue";
import ImportProfileTransferSftp from "./views/ImportProfileTransferSftp.vue";
import ImportProfileTransferHttp from "./views/ImportProfileTransferHttp.vue";
import ImportProfileTransferUpload from "./views/ImportProfileTransferUpload.vue";
import ImportProfileEditView from "./views/ImportProfileEditView.vue";
import ProcessReportsView from "./views/ProcessReportsView.vue";
import ProcessReportDetailView from "./views/ProcessReportDetailView.vue";
import ConfigView from "./views/ConfigView.vue";
import UsersView from "./views/UsersView.vue";
import UserCreateView from "./views/UserCreateView.vue";
import UserDetailsView from "./views/UserDetailsView.vue";
import UserEditView from "./views/UserEditView.vue";
import IntegrationConfigsView from "./views/IntegrationConfigsView.vue";
import IntegrationConfigDetailsView from "./views/IntegrationConfigDetailsView.vue";
import WatchlistOrderView from "./views/WatchlistOrderView.vue";
import NotificationConfigView from "./views/NotificationConfigView.vue";
export function createPortalUiRoutes() {
    //
    // Note about page titles: these are translated by the `updatePageTitle`
    //   router navigation guard.
    //
    const routes = [
        { path: '/:pathMatch(.*)*', redirect: { name: 'home' } },
        {
            name: 'login', path: '/login', component: LoginView, redirect: { name: 'login_form' },
            children: [
                {
                    name: 'login_form',
                    path: '',
                    component: LoginFormView,
                },
                {
                    name: 'login_reset_password',
                    path: 'reset-password',
                    component: LoginResetPasswordView,
                },
                {
                    name: 'login_access',
                    path: 'access',
                    component: LoginAccessView,
                },
            ]
        },
        { name: 'home', path: '/home', component: HomeView },
        {
            name: 'catalog',
            path: '/catalog',
            component: CatalogView,
            redirect: { name: 'catalog_category' },
            meta: {
                title: 'nav.catalog',
                permission: 'PORTAL_CATALOG_VIEW',
            },
            children: [
                {
                    name: 'catalog_category',
                    path: 'category/:category?',
                    component: CatalogCategoryView,
                    children: [
                        {
                            name: 'catalog_category_product',
                            path: 'product/:id',
                            component: ProductView,
                            redirect: { name: 'catalog_category_product_details' },
                            children: [
                                {
                                    name: 'catalog_category_product_details', path: '', component: ProductDetailsView
                                },
                                {
                                    name: 'catalog_category_product_variant', path: 'variant/:sku', component: ProductVariantView
                                },
                            ],
                        },
                    ]
                },
                {
                    name: 'catalog_product',
                    path: 'product/:id',
                    redirect: { name: 'catalog_category_product_details' },
                    children: [
                        {
                            name: 'catalog_product_details',
                            path: '',
                            redirect: { name: 'catalog_category_product_details' },
                        },
                        {
                            name: 'catalog_product_variant',
                            path: 'variant/:sku',
                            redirect: { name: 'catalog_category_product_variant' },
                        },
                    ],
                },
            ],
        },
        {
            name: 'orders',
            path: '/orders',
            component: OrdersView,
            meta: {
                title: 'nav.orders',
                permission: 'PORTAL_ORDERS_VIEW',
            },
            children: [
                { name: 'orders_order', path: '/orders/:orderNumber', component: OrderView },
                { name: 'orders_draft', path: '/orders/draft/:id', component: CartView },
            ]
        },
        {
            name: 'deliveries',
            path: '/deliveries',
            component: DeliveriesView,
            meta: {
                permission: 'PORTAL_ORDERS_VIEW',
            },
            children: [
                { name: 'deliveries_delivery', path: '/deliveries/:id', component: DeliveryView },
            ]
        },
        {
            name: 'integration',
            path: '/integration',
            redirect: { name: 'integration_config' },
            component: IntegrationView,
            meta: {
                permission: 'PORTAL_INTEGRATIONS_MANAGE',
            },
            children: [
                {
                    name: 'integration_config',
                    path: '/integration/config',
                    component: IntegrationConfigsView,
                    meta: {
                        title: 'nav.integration_config',
                    },
                    children: [
                        {
                            name: 'integration_config_details',
                            path: ':name',
                            component: IntegrationConfigDetailsView,
                        },
                    ],
                },
                {
                    name: 'integration_export',
                    path: '/integration/export',
                    component: ExportProfilesView,
                    meta: {
                        title: 'nav.integration_export',
                        permission: 'PORTAL_INTEGRATIONS_EXPORT',
                    },
                    children: [
                        {
                            name: 'integration_export_create',
                            path: 'create',
                            component: ExportProfileCreateView,
                        },
                        {
                            name: 'integration_export_details',
                            path: ':id',
                            component: ExportProfileDetailView,
                        },
                        {
                            name: 'integration_export_edit',
                            path: ':id/edit',
                            component: ExportProfileEditView,
                        },
                    ],
                },
                {
                    name: 'integration_import',
                    path: '/integration/import',
                    component: ImportProfilesView,
                    meta: {
                        title: 'nav.integration_import',
                        permission: 'PORTAL_INTEGRATIONS_IMPORT',
                    },
                    children: [
                        {
                            name: 'integration_import_create',
                            path: 'create',
                            component: ImportProfileCreateView,
                        },
                        {
                            name: 'integration_import_details',
                            path: ':id',
                            component: ImportProfileDetailView,
                            children: [
                                {
                                    name: 'integration_import_transfer_sftp',
                                    path: 'transfer/sftp',
                                    component: ImportProfileTransferSftp,
                                },
                                {
                                    name: 'integration_import_transfer_http',
                                    path: 'transfer/http',
                                    component: ImportProfileTransferHttp,
                                },
                                {
                                    name: 'integration_import_transfer_upload',
                                    path: 'transfer/upload',
                                    component: ImportProfileTransferUpload,
                                },
                            ],
                        },
                        {
                            name: 'integration_import_edit',
                            path: ':id/edit',
                            component: ImportProfileEditView,
                        },
                    ],
                },
                {
                    name: 'integration_reports',
                    path: '/integration/reports',
                    component: ProcessReportsView,
                    meta: {
                        title: 'nav.integration_reports',
                        permission: 'PORTAL_INTEGRATIONS_REPORT',
                    },
                    children: [
                        {
                            name: 'integration_reports_details',
                            path: '/integration/reports/:handler/:process/:id',
                            component: ProcessReportDetailView,
                            meta: {
                                title: 'Processing Report',
                            },
                        },
                    ],
                },
            ],
        },
        {
            name: 'config',
            path: '/config',
            component: ConfigView,
            redirect: { name: 'config_users' },
            children: [
                {
                    name: 'config_users',
                    path: 'users',
                    component: UsersView,
                    meta: {
                        title: 'nav.config_users',
                        permission: 'PORTAL_USERS_MANAGE',
                    },
                    children: [
                        {
                            name: 'config_users_create',
                            path: '/config/users/create',
                            component: UserCreateView,
                        },
                        {
                            name: 'config_users_user',
                            path: '/config/users/:id',
                            component: UserDetailsView,
                        },
                        {
                            name: 'config_users_edit',
                            path: '/config/users/:id/edit',
                            component: UserEditView,
                        },
                    ],
                },
                {
                    name: 'config_notifications',
                    path: 'notifications',
                    component: NotificationConfigView,
                    meta: {
                        title: 'nav.config_notifications',
                        permission: 'PORTAL_NOTIFICATIONS_MANAGE',
                    },
                }
            ],
        },
        {
            name: 'watchlist_order',
            path: '/watchlists/:token/order',
            component: WatchlistOrderView,
        },
    ];
    config.customRoutes.forEach(route => routes.push(route));
    return routes;
}
