import events from './EventBus';
import translate from './Translator';
export class Alerts {
    info(title, message, options) {
        return this.notify('info', title, message, options);
    }
    success(title, message, options) {
        return this.notify('success', title, message, options);
    }
    warning(title, message, options) {
        return this.notify('warning', title, message, options);
    }
    error(title, message, options) {
        return this.notify('error', title, message, options);
    }
    notify(type, title, message, opts) {
        const options = { ...defaultOptions, ...opts };
        if (options.timeout === undefined) {
            options.timeout = defaultTimeout(type);
        }
        const alertMessage = {
            type: type,
            message: message === undefined ? title : message,
            title: message === undefined ? undefined : title,
            options: options,
            dismiss: () => { },
            onDismiss: options.onDismiss || (() => { }),
        };
        alertMessage.dismiss = () => {
            events.$emit('app.alert-dismiss', alertMessage);
        };
        events.$emit('app.alert', alertMessage);
        return alertMessage;
    }
    clear() {
        events.$emit('app.alert-clear-all');
    }
}
const defaultOptions = {
    timeout: undefined,
    closable: true,
    links: [],
    onDismiss: () => { },
};
function defaultTimeout(type) {
    if (type === 'info' || type === 'success') {
        return 5000;
    }
}
export function handleHttpError(error) {
    if ('ERR_CANCELED' === error.code) {
        return;
    }
    if (error.response && error.response.status) {
        if (error.response.status >= 500) {
            alerts.error(translate('app.http_error'), translate('app.http_error.message'));
        }
        if (error.response.status === 403) {
            alerts.error(translate('app.security_error'), translate('app.security_error.access_denied'));
        }
        throw error.response.data;
    }
    throw error;
}
const alerts = new Alerts();
export default alerts;
