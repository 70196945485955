import log from './Logger';
const services = {};
export default {
    set(serviceId, instance) {
        if (services[serviceId]) {
            throw 'Service instance already set for ID: ' + serviceId;
        }
        services[serviceId] = instance;
        log('Set instance for: ' + serviceId);
    },
    get(serviceId) {
        const instance = services[serviceId];
        if (!instance) {
            throw 'No service instance found for ID: ' + serviceId;
        }
        return instance;
    },
    has(serviceId) {
        return !!services[serviceId];
    }
};
