import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import env from '../services/AppEnv';
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import translate from '@astina/paloma-common-ui/src/services/Translator';
import ValidationUtils from "@astina/paloma-common-ui/src/services/ValidationUtils";
export function addWatchlistItems(items, watchlistId, omitSuccessMessage) {
    return new AddWatchlistItems(items, watchlistId, omitSuccessMessage)
        .enqueue();
}
export function addWatchlistItem(item, watchlistId, omitSuccessMessage) {
    return addWatchlistItems([item], watchlistId, omitSuccessMessage);
}
export default class AddWatchlistItems extends AbstractCommand {
    items;
    watchlistId;
    omitSuccessMessage;
    constructor(items, watchlistId, omitSuccessMessage) {
        super();
        this.items = items;
        this.watchlistId = watchlistId;
        this.omitSuccessMessage = omitSuccessMessage === undefined ? false : omitSuccessMessage;
    }
    execute() {
        return paloma
            .execute(this.name(), {
            watchlistId: this.watchlistId || env.orders.activeWatchlist,
            items: this.items
        })
            .then(watchlist => {
            // if (!this.omitSuccessMessage) {
            //     alerts.success(translate(`command.${this.name()}.success`));
            // }
            return watchlist;
        })
            .catch((error) => {
            try {
                const validation = ValidationUtils.createValidation(error);
                ValidationUtils.alertAll(validation);
            }
            catch (e) {
                alerts.error(translate(`command.${this.name()}.error`));
            }
            throw error;
        });
    }
    name() {
        return 'add_watchlist_items';
    }
    icon() {
        return 'watchlist';
    }
    needsConfirmation() {
        return this.items.length > 10;
    }
    requiresPermission() {
        return 'PORTAL_WATCHLISTS_MANAGE';
    }
}
