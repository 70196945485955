import trans from '@astina/paloma-common-ui/src/services/Translator';
import palomaBackend from "@astina/paloma-common-ui/src/services/PalomaBackend";
import { handleHttpError } from "@astina/paloma-common-ui/src/services/Alerts";
import env from "../services/AppEnv";
const http = palomaBackend.http;
class ShopExportProfileRepository {
    list(searchRequest, page) {
        function toGridItemStatus(status) {
            if (status === 'inactive') {
                return 'disabled';
            }
            return 'neutral';
        }
        return http
            .get('orders/shop/export/profiles', {
            params: {
                page: page.number,
                size: page.size,
                sort: page.sort || 'name,asc',
                locale: env.locale,
            }
        })
            .then((response) => {
            const data = response.data;
            const page = {
                content: data.content.map((profile) => {
                    return {
                        id: profile.id,
                        title: profile.name,
                        topic: profile._meta.sourceLabel,
                        status: toGridItemStatus(profile.status),
                        attributes: {
                            id: profile.id,
                            name: profile.name,
                            status: profile.status,
                            schedule: profile._meta.scheduleHumanized,
                            format: profile._meta.formatLabel,
                            transport: profile._meta.transportLabel,
                            source: profile._meta.sourceLabel,
                            createdAt: profile.createdAt,
                            createdBy: profile.createdBy,
                            modifiedAt: profile.modifiedAt,
                            modifiedBy: profile.modifiedBy,
                            managedByIntegration: profile.managedByIntegration,
                        }
                    };
                }),
                number: data.number,
                size: data.size,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            };
            return page;
        })
            .catch(handleHttpError);
    }
    get(id) {
        return http.get(`orders/shop/export/profiles/${id}`, { params: { locale: env.locale, } })
            .then((response) => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    save(profile) {
        if (profile.id === null) {
            return http.post('orders/shop/export/profiles', profile)
                .then((response) => {
                return response.data;
            })
                .catch(handleHttpError);
        }
        return http.put(`orders/shop/export/profiles/${profile.id}`, profile)
            .then((response) => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    execute(id, taskExecutionType) {
        const params = {};
        if (taskExecutionType) {
            params['task-execution-type'] = taskExecutionType;
        }
        return http.post(`orders/shop/export/profiles/${id}/execute`, {}, { params: params })
            .then((response) => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    delete(id) {
        return http.delete(`orders/shop/export/profiles/${id}`)
            .then((response) => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    generateInputPassword(id, disable) {
        const params = {};
        if (disable !== undefined) {
            params.disable = disable;
        }
        return http.post(`orders/shop/export/profiles/${id}/generate-input-password`, { params: params })
            .then((response) => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    listSources() {
        return http.get('orders/shop/export/sources', { params: { locale: env.locale } })
            .then((response) => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getSource(name) {
        return http.get('orders/shop/export/sources', { params: { locale: env.locale } })
            .then((response) => {
            const source = response.data.find((s) => s.name === name);
            if (source === null) {
                throw `Source not found for name: ${name}`;
            }
            return source;
        })
            .catch(handleHttpError);
    }
    listConverters() {
        return http.get('orders/shop/export/converters', { params: { locale: env.locale } })
            .then((response) => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getConverter(name) {
        return http.get('orders/shop/export/converters', { params: { locale: env.locale } })
            .then((response) => {
            const converter = response.data.find((c) => c.name === name);
            if (converter === null) {
                throw `Converter not found for name: ${name}`;
            }
            return converter;
        })
            .catch(handleHttpError);
    }
    listTransports() {
        return http.get('orders/shop/export/transports', { params: { locale: env.locale } })
            .then((response) => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getTransport(name) {
        return http.get('orders/shop/export/transports', { params: { locale: env.locale } })
            .then((response) => {
            const transport = response.data.find((t) => t.name === name);
            if (transport === null) {
                throw `Transport not found for name: ${name}`;
            }
            return transport;
        })
            .catch(handleHttpError);
    }
    getGridConfig() {
        return Promise.resolve({
            name: 'export-profiles',
            capabilities: {
                search: false,
                bulkOperations: false,
            },
            columns: [
                {
                    name: 'name',
                    label: trans('export_profile.name'),
                    dataType: 'text',
                    visible: false,
                    sortable: false,
                    size: 'm'
                },
                {
                    name: 'status',
                    label: trans('export_profile.status'),
                    dataType: 'text',
                    visible: true,
                    sortable: false,
                    size: 's'
                },
                {
                    name: 'schedule',
                    label: trans('export_profile.schedule'),
                    dataType: 'text',
                    visible: false,
                    sortable: false,
                    size: 'm'
                },
                {
                    name: 'source',
                    label: trans('export_profile.source'),
                    dataType: 'text',
                    visible: false,
                    sortable: false,
                    size: 'm'
                },
                {
                    name: 'format',
                    label: trans('export_profile.format'),
                    dataType: 'text',
                    visible: true,
                    sortable: false,
                    size: 's'
                },
                {
                    name: 'transport',
                    label: trans('export_profile.transfer'),
                    dataType: 'text',
                    visible: true,
                    sortable: false,
                    size: 's'
                },
                {
                    name: 'createdAt',
                    label: trans('created_at'),
                    dataType: 'datetime',
                    visible: false,
                    sortable: false,
                    size: 'm'
                },
                {
                    name: 'createdBy',
                    label: trans('created_by'),
                    dataType: 'text',
                    visible: false,
                    sortable: false,
                    size: 'm'
                },
                {
                    name: 'modifiedAt',
                    label: trans('modified_at'),
                    dataType: 'datetime',
                    visible: false,
                    sortable: false,
                    size: 'm'
                },
                {
                    name: 'modifiedBy',
                    label: trans('modified_by'),
                    dataType: 'text',
                    visible: false,
                    sortable: false,
                    size: 'm'
                },
            ],
            filters: [],
        });
    }
    getGridItemsSource() {
        return {
            get: this.list
        };
    }
}
export default new ShopExportProfileRepository();
