export function transferMethodBadgeVariant(method) {
    switch (method) {
        case 'read':
        case 'GET':
            return 'active';
        case 'write':
        case 'POST':
        case 'PATCH':
        case 'PUT':
            return 'info';
        case 'delete':
        case 'DELETE':
            return 'danger';
    }
    return 'default';
}
export function transferStatusBadgeVariant(status) {
    switch (status) {
        case 'ongoing':
            return 'info';
        case 'success':
            return 'success';
        case 'error':
        case 'sender_error':
        case 'recipient_error':
            return 'error';
        case 'recipient_unavailable':
            return 'warn';
    }
    return 'default';
}
export function operationTransferGridName(connector, endpoint, operation) {
    return 'operation-transfer-' + connector + '_' + endpoint + '_' + operation;
}
