import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import orderUtils from "@astina/paloma-shop-common-ui/src/services/OrderUtils";
export function createOrderDraft() {
    return new CreateOrderDraft()
        .enqueue();
}
export default class CreateOrderDraft extends AbstractCommand {
    execute() {
        return paloma
            .execute(this.name(), {})
            .then((cart) => {
            orderUtils.handleCartModifications(cart);
            return cart;
        });
    }
    name() {
        return 'create_order_draft';
    }
    requiresPermission() {
        return 'PORTAL_ORDERS_CREATE';
    }
}
