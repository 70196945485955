import log from "./Logger";
import translate from './Translator';
import alerts from './Alerts';
function createValidation(error, messagePrefix) {
    if (!error || !error.errors) {
        return error;
    }
    if (error.fields) {
        return error;
    }
    const validation = {
        errors: [],
        fields: {}
    };
    error.errors.forEach((error) => {
        if (!error.property) {
            validation.errors.push(translateErrorMessage(error, messagePrefix));
            return;
        }
        var property = error.property.replace(/^entity./, '');
        if (!validation.fields.hasOwnProperty(property)) {
            validation.fields[property] = [];
        }
        validation.fields[property].push(translateErrorMessage(error, messagePrefix));
    });
    return validation;
}
/**
 * For an error message "MSG", with code "code", and parameter "parameter", this call will try the following translation
 * keys in order:
 *  errors.parameter.code
 *  errors.code
 * If none work, the message "MSG" itself is returned.
 */
function translateErrorMessage(error, messagePrefix) {
    var message = error.message || error;
    if (error.code) {
        var key = (messagePrefix ? messagePrefix + '.' : '')
            + 'errors.' + (error.property ? error.property + '.' : '')
            + error.code;
        var localizedMessage = translate(key, error.params);
        if (localizedMessage !== key) {
            message = localizedMessage;
        }
        else {
            // try w/o property
            if (error.property) {
                return translateErrorMessage({ code: error.code, message: error.message });
            }
            log('No localized error message found for error code: ' + error.code + ' (translation key: ' + key + ')');
        }
    }
    return message;
}
function alertErrors(validation) {
    validation.errors.forEach(error => {
        alerts.error(error);
    });
}
function alertAll(validation) {
    alertErrors(validation);
    Object.keys(validation.fields).forEach(property => {
        validation.fields[property].forEach((error) => {
            alerts.error((typeof error === 'string') ? error : error.message);
        });
    });
}
function emptyValidation() {
    return {
        errors: [],
        fields: {},
    };
}
function isValidation(val) {
    return val
        && Object.prototype.hasOwnProperty.call(val, 'errors')
        && Object.prototype.hasOwnProperty.call(val, 'fields');
}
export function handleHttpValidationError(error) {
    if (error.response && error.response.status && error.response.status === 400) {
        const validation = createValidation(error.response.data);
        if (validation !== error.response.data) {
            alertErrors(validation);
        }
        throw validation;
    }
    throw error;
}
export default class ValidationUtils {
    static createValidation = createValidation;
    static alertErrors = alertErrors;
    static alertAll = alertAll;
    static emptyValidation = emptyValidation;
    static handleHttpValidationError = handleHttpValidationError;
    static isValidation = isValidation;
}
