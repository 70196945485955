import palomaBackend from './PalomaBackend';
import { handleHttpError } from "./Alerts";
const http = palomaBackend.http;
class PalomaInput {
    getTransferInformation() {
        return http
            .get('input/transfer-information')
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    uploadImportFile(username, file) {
        const data = new FormData();
        data.append('file', file);
        return http
            .post(`input/import/${username}`, data, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
}
export default new PalomaInput();
