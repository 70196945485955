import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import orderUtils from "@astina/paloma-shop-common-ui/src/services/OrderUtils";
export function selectCartDeliveryLocation(cartId, locationId) {
    return new SelectCartDeliveryLocation(cartId, locationId)
        .enqueue();
}
export default class SelectCartDeliveryLocation extends AbstractCommand {
    cartId;
    locationId;
    constructor(cartId, locationId) {
        super();
        this.cartId = cartId;
        this.locationId = locationId;
    }
    execute() {
        return paloma
            .execute(this.name(), {
            cartId: this.cartId,
            locationId: this.locationId,
        })
            .then((cart) => {
            orderUtils.handleCartModifications(cart);
            return cart;
        });
    }
    name() {
        return 'select_cart_delivery_location';
    }
    requiresPermission() {
        return 'PORTAL_ORDERS_CREATE';
    }
}
