import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import env from '../services/AppEnv';
export function exportWatchlists() {
    return new ExportWatchlists()
        .enqueue();
}
export default class ExportWatchlists extends AbstractCommand {
    execute() {
        const url = `${env.path}/export/watchlists.xlsx`;
        window.open(url, '_blank');
        return Promise.resolve();
    }
    name() {
        return 'export_watchlists';
    }
    icon() {
        return 'file-excel';
    }
    requiresPermission() {
        return 'PORTAL_WATCHLISTS_VIEW';
    }
}
