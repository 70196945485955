import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import { clone } from 'lodash';
import repository from '../services/ShopExportProfileRepository';
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import translate from '@astina/paloma-common-ui/src/services/Translator';
export function duplicateShopExportProfile(profileId) {
    return new DuplicateShopExportProfile(profileId)
        .enqueue();
}
export default class DuplicateShopExportProfile extends AbstractCommand {
    profileId;
    constructor(profileId) {
        super();
        this.profileId = profileId;
    }
    execute() {
        return repository
            .get(this.profileId)
            .then(profile => {
            const duplicate = clone(profile);
            duplicate.id = null;
            duplicate.status = 'inactive';
            duplicate.name = `${profile.name} (copy)`;
            return repository.save(duplicate);
        })
            .then(profile => {
            alerts.success(translate('shop_export_profiles.duplicate_success'));
            return profile;
        })
            .catch(() => {
            alerts.error(translate('shop_export_profiles.duplicate_error'));
        });
    }
    name() {
        return 'duplicate_shop_export_profile';
    }
    icon() {
        return 'copy';
    }
    requiresPermission() {
        return 'PORTAL_INTEGRATIONS_EXPORT';
    }
}
