import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import env from '../services/AppEnv';
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import translate from '@astina/paloma-common-ui/src/services/Translator';
import ValidationUtils from "@astina/paloma-common-ui/src/services/ValidationUtils";
import orderUtils from "@astina/paloma-shop-common-ui/src/services/OrderUtils";
export function addWatchlistItemsToCart(watchlistId) {
    return new AddWatchlistItemsToCart(watchlistId)
        .enqueue();
}
export default class AddWatchlistItemsToCart extends AbstractCommand {
    watchlistId;
    constructor(watchlistId) {
        super();
        this.watchlistId = watchlistId;
    }
    execute() {
        return paloma
            .execute(this.name(), {
            watchlistId: this.watchlistId || env.orders.activeWatchlist,
        })
            .then((cart) => {
            orderUtils.handleCartModifications(cart);
            return cart;
        })
            .catch((error) => {
            try {
                const validation = ValidationUtils.createValidation(error);
                ValidationUtils.alertAll(validation);
            }
            catch (e) {
                alerts.error(translate(`command.${this.name()}.error`));
            }
            throw error;
        });
    }
    name() {
        return 'add_watchlist_items_to_cart';
    }
    icon() {
        return 'cart';
    }
    needsConfirmation() {
        return true;
    }
    requiresPermission() {
        return 'PORTAL_ORDERS_CREATE';
    }
}
