import env from "./AppEnv";
import { createHttpClient } from "./PalomaBackend";
const http = createHttpClient(env.path);
http.defaults.headers.post['Content-Type'] = 'multipart/form-data';
class PalomaFiles {
    upload(bucket, file, onProgress) {
        const uuid = self.crypto.randomUUID();
        const formData = new FormData();
        formData.append('file', file);
        const fileName = encodeURIComponent(file.name.replace(/\s/, '_'));
        return http
            .post(`/files/upload/${bucket}/${uuid}/${fileName}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function (ape) {
                onProgress && onProgress(ape.event);
            },
        })
            .then(response => {
            const objectInfo = response.data;
            return {
                objectName: objectInfo.objectName,
                internalUri: `files:${objectInfo.objectName}`,
            };
        });
    }
    uploadData(bucket, data, contentType, filename, onProgress) {
        const uuid = self.crypto.randomUUID();
        return http
            .post(`/files/upload/${bucket}/${uuid}`, data, {
            params: { filename: filename },
            headers: { 'Content-Type': contentType },
            onUploadProgress: function (ape) {
                onProgress && onProgress(ape.event);
            },
        })
            .then(response => {
            const objectInfo = response.data;
            return {
                objectName: objectInfo.objectName,
                internalUri: `files:${objectInfo.objectName}`,
            };
        });
    }
    get(pathOrUri) {
        const path = pathOrUri.indexOf('files:') === 0
            ? pathOrUri.substring('files:'.length)
            : pathOrUri;
        return http.get(`/files/_head/${path}`)
            .then(response => response.data);
    }
}
export default new PalomaFiles();
