import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import repository from '../services/ShopImportProfileRepository';
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import translate from '@astina/paloma-common-ui/src/services/Translator';
export function deleteShopImportProfile(profileId) {
    return new DeleteShopImportProfile(profileId)
        .enqueue();
}
export default class DeleteShopImportProfile extends AbstractCommand {
    profileId;
    constructor(profileId) {
        super();
        this.profileId = profileId;
    }
    execute() {
        return repository.delete(this.profileId)
            .then(() => {
            alerts.success(translate('shop_import_profiles.delete_success'));
            return this.profileId;
        });
    }
    needsConfirmation() {
        return true;
    }
    isDangerous() {
        return true;
    }
    requiresPermission() {
        return 'PORTAL_INTEGRATIONS_IMPORT';
    }
    name() {
        return 'delete_shop_import_profile';
    }
    icon() {
        return 'trash-alt';
    }
}
