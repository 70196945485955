export default class ProductUtils {
    static mergeAttributes(productMaster, variant) {
        return variant
            ? ProductUtils.createVariantAttributes(productMaster, variant)
            : ProductUtils.createAttributes(productMaster);
    }
    static createAttributes(variant) {
        const attributes = [];
        for (let key in variant.attributes) {
            const attr = variant.attributes[key];
            if (attr && attr.display !== 'none') {
                attributes.push(attr);
            }
        }
        attributes.sort((a1, a2) => {
            return a1.position - a2.position;
        });
        return attributes;
    }
    static createVariantAttributes(product, variant) {
        const productAttributes = this.createAttributes(product);
        const variantAttributes = this.createAttributes(variant);
        const attributes = variantAttributes;
        productAttributes.forEach(productAttribute => {
            if (!variantAttributes.find(attr => attr.type === productAttribute.type)) {
                attributes.push(productAttribute);
            }
        });
        return attributes;
    }
    static createImages(variant, size) {
        const images = [];
        variant.images.forEach(img => {
            const image = ProductUtils.createImage(img, size);
            if (image) {
                images.push(image);
            }
        });
        return images;
    }
    static createFirstImage(variant, size) {
        if (variant.images.length === 0) {
            return null;
        }
        return ProductUtils.createImage(variant.images[0], size);
    }
    static createImage(productImage, size) {
        if (productImage.sources.length === 0) {
            return null;
        }
        const source = productImage.sources.find(source => {
            return source.size === (size || 'medium');
            // We trust that sources are sorted by size descending
        }) || productImage.sources[productImage.sources.length - 1];
        return {
            title: productImage.title,
            url: source.url
        };
    }
    static createOptionsMatrix(configs, product) {
        const optionTypeNames = Object.keys(product.options);
        const matrixConfig = configs.find(config => {
            const dimensions = config.optionZ ? 3 : 2;
            if (optionTypeNames.length !== dimensions) {
                return false;
            }
            return optionTypeNames.includes(config.optionX)
                && optionTypeNames.includes(config.optionY)
                && (!config.optionZ || optionTypeNames.includes(config.optionZ));
        });
        if (!matrixConfig) {
            return null;
        }
        return {
            optionX: product.options[matrixConfig.optionX],
            optionY: product.options[matrixConfig.optionY],
            optionZ: matrixConfig.optionZ ? product.options[matrixConfig.optionZ] : undefined,
        };
    }
    static unitLabelOrCode(obj) {
        if (obj.unit.indexOf('unit.') === 0) {
            return obj.unitCode || obj.unit.substr('unit.'.length);
        }
        return obj.unit;
    }
    static createQuantityConfig(initial, variant, orderingUnit, portions) {
        const max = (variant.availability && variant.availability.inventoryMode === 'managed')
            ? Math.max(0, variant.availability.availableStock /* may be negative? */)
            : undefined;
        const price = variant.price;
        const ou = orderingUnit || variant.orderingUnits.find(ou => ou.unitCode === price.unitCode);
        let min = 0;
        if (ou && ou.minimumQuantity !== undefined) {
            min = +ou.minimumQuantity || 0 /* if NaN */;
        }
        let increment = ou && ou.quantityIncrement || undefined;
        return {
            // don't clamp quantity here, because we'd lie about the actual
            //   quantity! If the cart says we got 10 items but the max is 5,
            //   we really have 10 items in the cart! Even though it's an
            //   invalid value (now). If we clamp the value, the quantity input
            //   would show 5 but the cart would really contain 10, and the
            //   price would reflect 10 items.
            initial: initial,
            unit: price.unit,
            max: max,
            min: min,
            increment: increment,
            decimals: price.unitDecimals || 0,
            portioning: ou && ou.portioning,
            portioningUnit: ou && ou.portioningUnit,
            portioningNumerator: ou && ou.portioningNumerator,
            portioningDenominator: ou && ou.portioningDenominator,
            portions: portions,
        };
    }
    static createOrderingUnitForCartItem(item) {
        return {
            unit: item.unit,
            unitCode: item.unitCode,
            unitDecimals: 0, // TODO
            price: {
                currency: item.unitPricing.currency,
                unitPrice: item.unitPricing.netPriceFormatted,
                unitDecimals: 0, // TODO
                unit: item.unit,
                unitCode: item.unitCode,
                taxIncluded: false, // TODO
            },
        };
    }
}
export function unitLabelOrCode(obj) {
    return ProductUtils.unitLabelOrCode(obj);
}
