import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
export function setCartCustomerOrderNumber(cartId, customerOrderNumber) {
    return new SetCartCustomerOrderNumber(cartId, customerOrderNumber)
        .enqueue();
}
export default class SetCartCustomerOrderNumber extends AbstractCommand {
    cartId;
    customerOrderNumber;
    constructor(cartId, customerOrderNumber) {
        super();
        this.cartId = cartId;
        this.customerOrderNumber = customerOrderNumber;
    }
    execute() {
        return paloma
            .execute(this.name(), {
            cartId: this.cartId,
            customerOrderNumber: this.customerOrderNumber,
        });
    }
    name() {
        return 'set_cart_customer_order_number';
    }
    requiresPermission() {
        return 'PORTAL_ORDERS_CREATE';
    }
}
