import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import repository from '../services/ShopExportProfileRepository';
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import translate from '@astina/paloma-common-ui/src/services/Translator';
export function executeShopExportProfile(profileId) {
    return new ExecuteShopExportProfile(profileId)
        .enqueue();
}
export default class ExecuteShopExportProfile extends AbstractCommand {
    profileId;
    taskExecutionType;
    constructor(profileId, taskExecutionType) {
        super();
        this.profileId = profileId;
        this.taskExecutionType = taskExecutionType;
    }
    execute() {
        return repository.execute(this.profileId, this.taskExecutionType)
            .then(() => {
            alerts.success(translate('shop_export_profiles.execute_success'));
            return this.profileId;
        });
    }
    needsConfirmation() {
        return true;
    }
    name() {
        return 'execute_shop_export_profile';
    }
    icon() {
        return 'rocket';
    }
    requiresPermission() {
        return 'PORTAL_INTEGRATIONS_EXPORT';
    }
}
