import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import env from '../services/AppEnv';
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import translate from '@astina/paloma-common-ui/src/services/Translator';
import ValidationUtils from "@astina/paloma-common-ui/src/services/ValidationUtils";
export function removeWatchlistItems(ids, watchlistId) {
    return new RemoveWatchlistItems(ids, watchlistId)
        .enqueue();
}
export function deleteWatchlistItem(ids, watchlistId) {
    return removeWatchlistItems([ids], watchlistId);
}
export default class RemoveWatchlistItems extends AbstractCommand {
    ids;
    watchlistId;
    constructor(ids, watchlistId) {
        super();
        this.ids = ids;
        this.watchlistId = watchlistId;
    }
    execute() {
        return paloma
            .execute(this.name(), {
            watchlistId: this.watchlistId || env.orders.activeWatchlist,
            ids: this.ids
        })
            .then(watchlist => {
            return watchlist;
        })
            .catch((error) => {
            try {
                const validation = ValidationUtils.createValidation(error);
                ValidationUtils.alertAll(validation);
            }
            catch (e) {
                alerts.error(translate(`command.${this.name()}.error`));
            }
            throw error;
        });
    }
    name() {
        return 'remove_watchlist_items';
    }
    icon() {
        return 'trash-alt';
    }
    needsConfirmation() {
        return this.ids.length > 10;
    }
    requiresPermission() {
        return 'PORTAL_WATCHLISTS_MANAGE';
    }
}
