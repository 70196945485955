import env from "../services/AppEnv";
export default class LocalizedUtils {
    static isLocalizedValue(value) {
        if (!value || !LocalizedUtils.isObject(value) || Array.isArray(value)) {
            return false;
        }
        if (!value.hasOwnProperty('values')) {
            return false;
        }
        if (!Array.isArray(value.values) || value.values.length === 0) {
            return false;
        }
        const first = value.values[0];
        return first.hasOwnProperty('value') && first.hasOwnProperty('locale');
    }
    static findLocalizedValue(value) {
        const localized = LocalizedUtils.findLocalized(value);
        return (localized && localized.value) || null;
    }
    static findLocalizedLocale(value) {
        const localized = LocalizedUtils.findLocalized(value);
        return (localized && localized.locale) || null;
    }
    static findLocalized(value) {
        if (value.values.length === 0) {
            return null;
        }
        const forLocale = value.values
            .find(value => value.locale && value.locale.indexOf(env.locale) === 0);
        if (forLocale) {
            return forLocale;
        }
        const forRootOrEn = value.values
            .find(value => (!value.locale && value.locale === '') || value.locale.indexOf('en') === 0);
        return forRootOrEn || null;
    }
    static isLocalizedViewItem(viewItem) {
        return viewItem.groups.length === 1
            && viewItem.groups[0].name === 'values'
            && viewItem.groups[0].items.length === 2
            && viewItem.groups[0].items[0].name === 'locale'
            && viewItem.groups[0].items[1].name === 'value';
    }
    static isObject(value) {
        return (typeof value) === 'object';
    }
}
