import moment from "moment";
import numeral from "numeral";
import BootstrapVueNext from 'bootstrap-vue-next';
import VueClipboard from 'vue3-clipboard';
import { VueSelect } from "vue-select";
import { initTranslator } from "./Translator";
import env from './AppEnv';
import { isGranted } from './AccessManager';
import FormFields from "../components/form/FormFields.vue";
import FormGroup from "../components/form/FormGroup.vue";
import Icon from "../components/Icon.vue";
import { initPalomaLogService } from "./PalomaLogService";
import { initPalomaProcessService } from "./PalomaProcessService";
import { dateFilter, dateTimeFilter, dateTimeMillisFilter, dateTimeSecFilter, dateTimeTzFilter, fromNowFilter, timeFilter, timeMillisFilter, timeSecFilter } from "../util/CommonUiFilters";
// @ts-ignore
const MESSAGES = window._MESSAGES || null;
function configureSecurity(app) {
    // ATTENTION! Changes and additions to globalProperties functions need to be reflected in common/ui/ui/src/ui.d.ts!
    app.config.globalProperties.$isGranted = isGranted;
}
function configureDefaultComponents(app) {
    app.component('icon', Icon);
}
function configureForms(app) {
    app.component('v-select', VueSelect);
    app.component('form-fields', FormFields);
    app.component('form-group', FormGroup);
}
function configureTranslator(app, router) {
    initTranslator(app, env.locale, router, MESSAGES);
}
function configureDirectives(app) {
    app.directive('focus', {
        mounted: function (el) {
            el.focus();
        }
    });
}
function configureBootstrap(app) {
    app.use(BootstrapVueNext);
}
function configureMomentJs(app, language) {
    moment.locale(language);
    // ATTENTION! Changes and additions to globalProperties functions need to be reflected in common/ui/ui/src/ui.d.ts!
    app.config.globalProperties.$date = dateFilter;
    // ATTENTION! Changes and additions to globalProperties functions need to be reflected in common/ui/ui/src/ui.d.ts!
    app.config.globalProperties.$dateTime = dateTimeFilter;
    // ATTENTION! Changes and additions to globalProperties functions need to be reflected in common/ui/ui/src/ui.d.ts!
    app.config.globalProperties.$dateTimeTz = dateTimeTzFilter;
    // ATTENTION! Changes and additions to globalProperties functions need to be reflected in common/ui/ui/src/ui.d.ts!
    app.config.globalProperties.$dateTimeSec = dateTimeSecFilter;
    // ATTENTION! Changes and additions to globalProperties functions need to be reflected in common/ui/ui/src/ui.d.ts!
    app.config.globalProperties.$dateTimeMillis = dateTimeMillisFilter;
    // ATTENTION! Changes and additions to globalProperties functions need to be reflected in common/ui/ui/src/ui.d.ts!
    app.config.globalProperties.$time = timeFilter;
    // ATTENTION! Changes and additions to globalProperties functions need to be reflected in common/ui/ui/src/ui.d.ts!
    app.config.globalProperties.$timeSec = timeSecFilter;
    // ATTENTION! Changes and additions to globalProperties functions need to be reflected in common/ui/ui/src/ui.d.ts!
    app.config.globalProperties.$timeMillis = timeMillisFilter;
    // ATTENTION! Changes and additions to globalProperties functions need to be reflected in common/ui/ui/src/ui.d.ts!
    app.config.globalProperties.$fromNow = fromNowFilter;
}
function configureNumeral(app, language) {
    const localeSettings = {
        delimiters: {
            thousands: '\'',
            decimal: '.',
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't',
        },
        ordinal(num) {
            return '.';
        },
        currency: {
            symbol: '', // Not locale specific ...
        }
    };
    env.translations.locales
        .filter(locale => 'en' !== locale) // 'en' is registered by default
        .forEach(locale => {
        numeral.register('locale', locale, localeSettings);
    });
    numeral.locale(language);
}
function configureClipboard(app) {
    app.use(VueClipboard, {});
}
function getLanguage() {
    return env.locale.replace(/([_-]).+$/, '');
}
export default function PalomaApp(app, router, options) {
    initPalomaLogService();
    initPalomaProcessService();
    configureSecurity(app);
    configureDefaultComponents(app);
    configureForms(app);
    configureTranslator(app, router);
    configureDirectives(app);
    configureBootstrap(app);
    const language = getLanguage();
    configureMomentJs(app, language);
    configureNumeral(app, language);
    configureClipboard(app);
}
