import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import alerts from "@astina/paloma-common-ui/src/services/Alerts";
import trans from '@astina/paloma-common-ui/src/services/Translator';
export function saveWatchlist(watchlist) {
    return new SaveWatchlist(watchlist)
        .enqueue();
}
export default class SaveWatchlist extends AbstractCommand {
    watchlist;
    constructor(watchlist) {
        super();
        this.watchlist = watchlist;
    }
    execute() {
        const isCreate = !this.watchlist.watchlistId;
        return paloma
            .execute(this.name(), this.watchlist)
            .then((watchlist) => {
            if (isCreate) {
                alerts.success(trans(`command.${this.name()}.success`, { name: watchlist.name }));
            }
            return watchlist;
        })
            .catch((e) => {
            alerts.error(trans(`command.${this.name()}.error`));
            throw e;
        });
    }
    requiresPermission() {
        return 'PORTAL_WATCHLISTS_MANAGE';
    }
    name() {
        return 'save_watchlist';
    }
    icon() {
        return 'save';
    }
}
