const PROCESS_REPORT_PATTERN = /^(event|task|action|monitor|other)\[([^#]+)#([^\]]+)]$/;
export function isProcessReportReference(str) {
    return !str
        && null !== str.match(PROCESS_REPORT_PATTERN);
}
export function parseProcessReportReference(str) {
    if (!str) {
        return null;
    }
    const match = str.match(PROCESS_REPORT_PATTERN);
    if (!match) {
        return null;
    }
    return {
        handler: match[1],
        process: match[2],
        id: match[3],
    };
}
