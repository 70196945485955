import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import env from '../services/AppEnv';
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import translate from '@astina/paloma-common-ui/src/services/Translator';
import orderUtils from "@astina/paloma-shop-common-ui/src/services/OrderUtils";
export function updateCartItems(items, cartId) {
    return new UpdateCartItems(items, cartId)
        .enqueue();
}
export default class UpdateCartItems extends AbstractCommand {
    items;
    cartId;
    constructor(items, cartId) {
        super();
        this.items = items;
        this.cartId = cartId;
    }
    execute() {
        return paloma
            .execute(this.name(), {
            cartId: this.cartId || env.orders.activeDraft,
            items: this.items
        })
            .then((cart) => {
            orderUtils.handleCartModifications(cart);
            return cart;
        })
            .catch(error => {
            if (error && error.status && error.status === 404) {
                alerts.error(translate('cart.not_found'));
            }
            else {
                alerts.error(translate('cart.update_items.error'));
            }
            throw error;
        });
    }
    name() {
        return 'update_cart_items';
    }
    requiresPermission() {
        return 'PORTAL_ORDERS_CREATE';
    }
}
