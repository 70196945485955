<template>
    <div>
        <router-link :to="{name: 'contact'}"
                     class="dropdown__item">
            {{ $t('nav.contact') }}
        </router-link>
        <router-link :to="{name: 'imprint'}"
                     class="dropdown__item">
            {{ $t('nav.imprint') }}
        </router-link>
        <a :href="$t('gtc.url')" target="_blank" class="dropdown__item">
            {{ $t('nav.gtc') }}
        </a>
        <a :href="$t('privacy.url')" target="_blank" class="dropdown__item">
            {{ $t('nav.privacy') }}
        </a>
    </div>
</template>

<style scoped lang="scss">

</style>