<template>
    <div class="currently-unavailable">
        {{ $t('errors.currently_unavailable') }}
    </div>
</template>

<style scoped lang="scss">

    @import "../css/theme";

    .currently-unavailable {
        color: $grey-4;
        background: $grey-0;
        padding: .25rem;
    }

</style>