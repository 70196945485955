import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import palomaInput from "@astina/paloma-common-ui/src/services/PalomaInput";
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import translate from '@astina/paloma-common-ui/src/services/Translator';
export function uploadShopImportProfileFile(profile, file) {
    return new UploadShopImportProfileFile(profile, file)
        .enqueue();
}
export default class UploadShopImportProfileFile extends AbstractCommand {
    profile;
    file;
    constructor(profile, file) {
        super();
        this.profile = profile;
        this.file = file;
    }
    execute() {
        return palomaInput
            .uploadImportFile(this.profile.inputUsername, this.file)
            .then(() => {
            alerts.success(translate('shop_import_profiles.upload_file_success'));
        })
            .catch(() => {
            alerts.error(translate('shop_import_profiles.upload_file_error'));
        });
    }
    name() {
        return 'upload_shop_import_profile_file';
    }
    icon() {
        return 'upload';
    }
    requiresPermission() {
        return 'PORTAL_INTEGRATIONS_IMPORT';
    }
}
