<template>
    <p v-if="$te('terms.accept_additional')">
        {{ $t('terms.accept_additional') }}
    </p>
</template>

<style lang="scss" scoped>

    @import "../css/theme";

    p {
        margin: 2rem 0 0 0;
        color: $grey-5;
        font-size: $text-size-8;
    }

</style>