import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
export function deleteOrderDraft(cartId) {
    return new DeleteOrderDraft(cartId)
        .enqueue();
}
export default class DeleteOrderDraft extends AbstractCommand {
    cartId;
    constructor(cartId) {
        super();
        this.cartId = cartId;
    }
    execute() {
        return paloma
            .execute(this.name(), {
            id: this.cartId,
        })
            .then(() => {
            return this.cartId;
        });
    }
    needsConfirmation() {
        return true;
    }
    isDangerous() {
        return true;
    }
    requiresPermission() {
        return 'PORTAL_ORDERS_CREATE';
    }
    name() {
        return 'delete_order_draft';
    }
    icon() {
        return 'trash-alt';
    }
}
