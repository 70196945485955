import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import trans from '@astina/paloma-common-ui/src/services/Translator';
export function saveUser(user) {
    return new SaveUser(user).enqueue();
}
export default class SaveUser extends AbstractCommand {
    user;
    constructor(user) {
        super();
        this.user = user;
    }
    execute() {
        return paloma
            .execute(this.name(), this.user)
            .then(user => {
            alerts.success(trans(`command.${this.name()}.success`));
            return user;
        })
            .catch(error => {
            alerts.error(trans(`command.${this.name()}.error`));
            throw error;
        });
    }
    name() {
        return "save_user";
    }
    icon() {
        return 'save';
    }
    requiresPermission() {
        return 'PORTAL_USERS_MANAGE';
    }
}
