import { cloneDeep as _cloneDeep } from 'lodash';
import events from './EventBus';
import log from "./Logger";
import ValidationUtils from './ValidationUtils';
import { handleHttpError } from './Alerts';
class CommandQueue {
    queue = [];
    running = false;
    add(_command) {
        // Create a copy because _command may be mutated
        // until we execute this command.
        const command = _cloneDeep(_command);
        const execution = {
            command: command,
            resolve: () => {
            },
            reject: () => {
            },
        };
        const promise = new Promise((resolve, reject) => {
            execution.resolve = resolve;
            execution.reject = reject;
        });
        this.queue.push(execution);
        this.running || this.run();
        return promise;
    }
    run() {
        this.running = true;
        const interval = window.setInterval(() => {
            const execution = this.queue.shift();
            if (execution) {
                log('Executing', execution.command.name(), execution.command);
                execution.command.execute()
                    .then((value) => {
                    events.$emit('command.' + execution.command.name() + '.success', value);
                    return execution.resolve(value);
                })
                    .catch((error) => {
                    if (isHttp400Error(error)) {
                        events.$emit('command.' + execution.command.name() + '.invalid', error);
                        return execution.reject(ValidationUtils.createValidation(error.response.data));
                    }
                    if (isValidationErrors(error)) {
                        events.$emit('command.' + execution.command.name() + '.invalid', error);
                        return execution.reject(ValidationUtils.createValidation(error));
                    }
                    try {
                        handleServerError(error);
                    }
                    catch (e) { }
                    events.$emit('command.' + execution.command.name() + '.error', error);
                    return execution.reject(error);
                });
            }
            else {
                window.clearInterval(interval);
                this.running = false;
            }
        }, 100);
    }
}
function isValidationErrors(error) {
    return error && error.hasOwnProperty('errors') && (typeof error.errors) === 'object';
}
const commandQueue = new CommandQueue();
function handleServerError(error) {
    if (!isHttpServerError(error) && !isHttp403Error(error)) {
        return;
    }
    handleHttpError(error);
}
function isHttpServerError(error) {
    return error && error.response && error.response.status && error.response.status >= 500;
}
function isHttp400Error(error) {
    return isHttpStatus(error, 400);
}
function isHttp403Error(error) {
    return isHttpStatus(error, 403);
}
function isHttpStatus(error, status) {
    return error && error.response && error.response.status && error.response.status === status;
}
export class AbstractCommand {
    enqueue() {
        return commandQueue.add(this);
    }
    needsConfirmation() {
        return false;
    }
    isDangerous() {
        return false;
    }
    requiresPermission() {
        return null;
    }
    icon() {
        return undefined;
    }
}
