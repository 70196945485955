import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
export function activateCustomer(customerId) {
    return new ActivateCustomer(customerId)
        .enqueue();
}
export default class ActivateCustomer extends AbstractCommand {
    customerId;
    constructor(customerId) {
        super();
        this.customerId = customerId;
    }
    execute() {
        return paloma
            .execute(this.name(), {
            customerId: this.customerId
        });
    }
    name() {
        return 'activate_customer';
    }
}
