import _ from 'lodash';
import palomaBackend from "@astina/paloma-common-ui/src/services/PalomaBackend";
import env from '@astina/paloma-common-ui/src/services/AppEnv';
import { handleHttpError } from "@astina/paloma-common-ui/src/services/Alerts";
import { catalog as config } from "./RepositoryConfig";
const http = palomaBackend.http;
function pricingContextParams(pricingContext) {
    return {
        'context.currency': pricingContext && pricingContext.currency,
        'context.date': pricingContext && pricingContext.date,
        'context.priceGroups': pricingContext && pricingContext.priceGroups && pricingContext.priceGroups.join(','),
        'context.preferredUnit': pricingContext && pricingContext.preferredUnit
    };
}
class ProductRepository {
    list(searchRequest, page, attributes) {
        return http
            .post(`${config.path}/list_products`, searchRequest, {
            params: {
                locale: env.locale,
                page: page.number,
                size: page.size,
                sort: page.sort && (page.sort + (page.order ? ',' + page.order : null)),
                attributes: attributes.join(','),
            }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    listVariants(searchRequest, page, attributes) {
        return this.listVariantsWithContext(searchRequest, page, attributes);
    }
    listVariantsWithContext(searchRequest, page, attributes, channel, catalog, customerId, userId, pricingContext, collection) {
        return http
            .post(`${config.path}/list_product_variants`, searchRequest, {
            params: {
                channel: channel,
                catalog: catalog,
                customerId: customerId,
                userId: userId,
                ...pricingContextParams(pricingContext),
                collection: collection,
                locale: env.locale,
                page: page.number,
                size: page.size,
                sort: page.sort && (page.sort + (page.order ? ',' + page.order : null)),
                attributes: attributes.join(','),
            }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    listSuggestions(query) {
        return http
            .get(`${config.path}/list_suggestions`, {
            params: {
                locale: env.locale,
                query: query,
            },
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    get(itemNumber) {
        return http
            .get(`${config.path}/get_product`, {
            params: {
                locale: env.locale,
                itemNumber: itemNumber,
            }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getProductByVariant(itemNumber, sku, channel, catalog, pricingContext) {
        return http
            .get(`${config.path}/get_product_by_variant`, {
            params: {
                locale: env.locale,
                itemNumber: itemNumber,
                sku: sku,
                channel: channel,
                catalog: catalog,
                ...pricingContextParams(pricingContext)
            }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getVariantPrice(sku, quantity, unit, channel, pricingContext) {
        return http
            .get(`${config.path}/get_variant_price`, {
            params: {
                locale: env.locale,
                sku: sku,
                quantity: quantity,
                unit: unit,
                channel: channel,
                ...pricingContextParams(pricingContext)
            }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getGridConfig(category) {
        return http
            .get(`${config.path}/get_grid_config`, {
            params: { locale: env.locale, category: category || null }
        })
            .then(response => response.data)
            .catch(handleHttpError);
    }
    listCategories(depth, includeUnlisted) {
        const params = {
            locale: env.locale,
        };
        if (depth) {
            params['depth'] = depth;
        }
        if (includeUnlisted) {
            params['includeUnlisted'] = includeUnlisted;
        }
        return http
            .get(`${config.path}/list_categories`, {
            params: params
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getCategory(code, depth) {
        return http
            .get(`${config.path}/get_category`, {
            params: {
                locale: env.locale,
                code: code,
                depth: depth || 0,
            }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getGridItemsSource() {
        return {
            get: this.list
        };
    }
    getCategoryGridItemsSource(categoryCode) {
        return {
            get: (searchRequest, page, attributes) => {
                const req = _.clone(searchRequest);
                req.filters.push({
                    attribute: '_main_category',
                    values: [categoryCode],
                });
                return this.list(req, page, attributes);
            }
        };
    }
    getVariantGridConfig(channel) {
        return http
            .get(`${config.path}/get_variant_grid_config`, {
            params: {
                channel: channel,
                locale: env.locale,
            },
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getVariantGridItemsSource(channel, catalog, customerId, userId, pricingContext) {
        return {
            get: (searchRequest, page, attributes) => {
                return this.listVariantsWithContext(searchRequest, page, attributes, channel, catalog, customerId, userId, pricingContext);
            },
        };
    }
    getVariantCollectionConfig(channel, customerId) {
        return http
            .get(`${config.path}/get_variant_collection_config`, {
            params: {
                channel: channel,
                locale: env.locale,
                customerId: customerId,
            },
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    deleteVariantCollectionItem(channel, collection, itemId, customerId) {
        return http
            .post(`${config.path}/delete_collection_item`, undefined, {
            params: {
                channel: channel,
                collection: collection,
                itemId: itemId,
                customerId: customerId,
            },
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
}
export default new ProductRepository();
