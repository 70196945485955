import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import repository from '../services/ShopExportProfileRepository';
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import translate from '@astina/paloma-common-ui/src/services/Translator';
export function generateShopExportProfilePassword(profileId) {
    return new GenerateShopExportProfilePassword(profileId)
        .enqueue();
}
export default class GenerateShopExportProfilePassword extends AbstractCommand {
    profileId;
    disable;
    constructor(profileId, disable) {
        super();
        this.profileId = profileId;
        this.disable = disable;
    }
    execute() {
        return repository.generateInputPassword(this.profileId, this.disable)
            .then(passwordResponse => {
            alerts.success(translate('shop_import_profiles.generate_password_success'));
            return passwordResponse;
        });
    }
    needsConfirmation() {
        return true;
    }
    isDangerous() {
        return true;
    }
    name() {
        return 'generate_shop_export_profile_password';
    }
    icon() {
        return 'key';
    }
    requiresPermission() {
        return 'PORTAL_INTEGRATIONS_EXPORT';
    }
}
