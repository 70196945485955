import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from "@astina/paloma-common-ui/src/services/PalomaBackend";
import alerts from "@astina/paloma-common-ui/src/services/Alerts";
import trans from '@astina/paloma-common-ui/src/services/Translator';
import env from '../services/AppEnv';
const http = paloma.http;
export function importWatchlists(file) {
    return new ImportWatchlists(file)
        .enqueue();
}
export default class ImportWatchlists extends AbstractCommand {
    file;
    constructor(file) {
        super();
        this.file = file;
    }
    execute() {
        const formData = new FormData();
        formData.append('file', this.file);
        return http
            .post('customers/import_watchlists', formData, {
            params: {
                locale: env.locale,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
            const report = response.data;
            if (!ImportWatchlists.hasErrors(report)) {
                alerts.success(trans(`command.${this.name()}.success`));
            }
            return report;
        })
            .catch((e) => {
            alerts.error(trans(`command.${this.name()}.error`));
            throw e;
        });
    }
    name() {
        return 'import_watchlists';
    }
    icon() {
        return 'file-excel';
    }
    requiresPermission() {
        return 'PORTAL_WATCHLISTS_VIEW';
    }
    static hasErrors(report) {
        return report.results
            .flatMap(result => result.importRows)
            .find(row => row.status === 'not_ok') !== undefined;
    }
}
