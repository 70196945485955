import { createApp } from 'vue';
import PortalApp from './App.vue';
import PalomaApp from '@astina/paloma-common-ui/src/services/PalomaApp';
import ProductUtils from "@astina/paloma-shop-common-ui/src/services/ProductUtils";
import { createRouter, createWebHistory } from "vue-router";
import { createPortalUiRoutes } from './routes';
import routerUtils from '@astina/paloma-common-ui/src/util/RouterUtils';
export function createPortalUiApp() {
    const app = createApp(PortalApp);
    const router = createRouter({
        history: createWebHistory(),
        routes: createPortalUiRoutes()
    });
    app.use(router);
    app.use(PalomaApp, router);
    // ATTENTION! Changes and additions to globalProperties functions need to be reflected in ComponentCustomProperties!
    // See shop/common-ui/ui/src/types/augmented-global-properties.d.ts
    app.config.globalProperties.$unit = ProductUtils.unitLabelOrCode;
    // Router
    router.beforeEach(routerUtils.checkDirtyForms);
    router.beforeEach(routerUtils.checkPermission);
    router.beforeEach(routerUtils.updatePageTitle);
    return app;
}
