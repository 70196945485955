import env from '../services/AppEnv';
import { handleHttpError } from "@astina/paloma-common-ui/src/services/Alerts";
import palomaBackend from "@astina/paloma-common-ui/src/services/PalomaBackend";
const http = palomaBackend.http;
class ConfigRepository {
    getNotificationSettingsForm() {
        return http
            .get('orders/forms/customer-notification-settings', {
            params: {
                locale: env.locale,
            },
        })
            .then(response => response.data)
            .catch(handleHttpError);
    }
    saveNotificationSettings(settings) {
        return http
            .post('orders/customer-notification-settings', settings, {
            params: {
                locale: env.locale,
            },
        })
            .then(response => response.data)
            .catch(handleHttpError);
    }
}
export default new ConfigRepository();
