import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import env from '../services/AppEnv';
import orderUtils from "@astina/paloma-shop-common-ui/src/services/OrderUtils";
export function activateCart(cartId) {
    return new ActivateCart(cartId)
        .enqueue();
}
export default class ActivateCart extends AbstractCommand {
    cartId;
    constructor(cartId) {
        super();
        this.cartId = cartId;
    }
    execute() {
        env.orders.activeDraft = this.cartId;
        return paloma
            .execute(this.name(), {
            cartId: this.cartId
        })
            .then((cart) => {
            orderUtils.handleCartModifications(cart);
            return cart;
        })
            .catch(error => {
            env.orders.activeDraft = null;
            return error;
        });
    }
    name() {
        return 'activate_cart';
    }
}
