import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import repository from '../services/ShopExportProfileRepository';
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import translate from '@astina/paloma-common-ui/src/services/Translator';
export function deleteShopExportProfile(profileId) {
    return new DeleteShopExportProfile(profileId)
        .enqueue();
}
export default class DeleteShopExportProfile extends AbstractCommand {
    profileId;
    constructor(profileId) {
        super();
        this.profileId = profileId;
    }
    execute() {
        return repository.delete(this.profileId)
            .then(() => {
            alerts.success(translate('shop_export_profiles.delete_success'));
            return this.profileId;
        })
            .catch(() => {
            alerts.error(translate('export_shop_profiles.delete_error'));
        });
    }
    needsConfirmation() {
        return true;
    }
    isDangerous() {
        return true;
    }
    requiresPermission() {
        return 'PORTAL_INTEGRATIONS_EXPORT';
    }
    name() {
        return 'delete_shop_export_profile';
    }
    icon() {
        return 'trash-alt';
    }
}
