import { AbstractCommand } from "./CommandQueue";
import serviceLocator from './ServiceLocator';
import alerts, {} from './Alerts';
import trans from './Translator';
export class ProcessOperationCommand extends AbstractCommand {
    report;
    operation;
    constructor(report, operation) {
        super();
        this.report = report;
        this.operation = operation;
    }
    execute() {
        const processService = serviceLocator.get('process_service');
        return processService.callOperation(this.report, this.operation)
            .then(() => {
            let options = undefined;
            if (this.operation.type === 'repeat' && this.report.trigger.trace) {
                options = {
                    timeout: 30000,
                    links: [{
                            route: {
                                name: 'processing_reports',
                                query: { searchRequest: encodeURIComponent(JSON.stringify({ query: this.report.trigger.trace })) },
                            },
                            label: 'Show report',
                            icon: 'logs',
                        }]
                };
            }
            alerts.success(trans(this.name() + '_started'), undefined, options);
        })
            .catch(() => {
            alerts.error(trans(this.name() + '_error'));
        });
    }
    name() {
        return "process_" + this.operation.type;
    }
    needsConfirmation() {
        return true;
    }
    icon() {
        switch (this.operation.type) {
            case 'retry':
                return 'redo';
            case 'skip':
                return 'forward';
            case 'abort':
                return 'ban';
            case 'repeat':
                return 'repeat';
        }
        return;
    }
}
export class ProcessStageOperationCommand extends AbstractCommand {
    report;
    stage;
    operation;
    constructor(report, stage, operation) {
        super();
        this.report = report;
        this.stage = stage;
        this.operation = operation;
    }
    execute() {
        const processService = serviceLocator.get('process_service');
        return processService.callStageOperation(this.report, this.stage, this.operation)
            .then(() => {
            alerts.success(trans(this.name() + '_started'));
        })
            .catch(() => {
            alerts.error(trans(this.name() + '_error'));
        });
    }
    name() {
        return "process_stage_" + this.operation.type;
    }
    needsConfirmation() {
        return true;
    }
}
export function processStageIcon(stage) {
    switch (stage.type) {
        case "start":
            return 'traffic-light-go';
        case "initialization":
            return 'truck-loading';
        case "persistence":
            return 'database';
        case "transfer":
            return 'truck-container';
        case "synchronization":
            return 'sync';
        case "notification":
            return 'envelope';
        case "cleanup":
            return 'vacuum';
        case "completion":
            return 'check';
    }
    const wordsMap = {
        'payment': 'coin',
        'inventory': 'warehouse',
        'warehouse': 'warehouse',
        'download': 'download',
        'send': 'paper-plane',
        'report': 'clipboard-list',
        'extract': 'file-archive',
        'acknowledge': 'check',
        'confirm': 'check',
        'backup': 'database',
        'price': 'coin',
    };
    for (let word in wordsMap) {
        if (wordsMap.hasOwnProperty(word) && stage.name.indexOf(word) !== -1) {
            return wordsMap[word];
        }
    }
    return 'cog';
}
