import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import translate from '@astina/paloma-common-ui/src/services/Translator';
import events from '@astina/paloma-common-ui/src/services/EventBus';
export default class OrderUtils {
    static createOrderTotals(order) {
        return {
            itemsTotal: order.taxIncluded
                ? order.itemsPricing.grossPriceFormatted
                : order.itemsPricing.netPriceFormatted,
            netOrderTotal: order.orderPricing.netPriceFormatted,
            orderTotal: order.orderPricing.grossPriceFormatted,
            includedTaxes: order.taxSummary.taxes.map(tax => {
                return {
                    type: tax.type,
                    name: tax.name,
                    rate: tax.rateFormatted,
                    amount: tax.amountFormatted
                };
            }),
        };
    }
    static handleCartModifications(cart) {
        const modifications = cart.modifications || [];
        if (modifications.length === 0) {
            return;
        }
        events.$emit('cart-modifications', modifications);
        OrderUtils.showCartModificationAlerts(cart);
    }
    static showCartModificationAlerts(cart) {
        const modifications = cart.modifications || [];
        modifications.forEach(modification => {
            const reason = modification.params['reason'];
            const sku = modification.params['item_sku'];
            let message = undefined;
            if (reason && sku) {
                message = translate(`cart.item_error.${reason}`, { sku: sku });
            }
            alerts.warning(translate(`cart.modification.${modification.code}`, modification.params), message, { timeout: 5000 });
        });
    }
    static calculateQuantityPerPortion(quantity, config, currentQuantity) {
        if (quantity.portions === undefined) {
            throw 'quantity.portions is undefined';
        }
        const qpp = parseFloat(((currentQuantity || quantity.quantity) / quantity.portions).toFixed(config.decimals));
        return qpp
            * (config.portioningNumerator || 1)
            / (config.portioningDenominator || 1);
    }
}
