import axios from 'axios';
import { createI18n } from "vue-i18n";
import env from './AppEnv';
import log from "./Logger";
// this will be the last instance that has been initialized. Might cause
//   problems when having multiple Vue apps!
let i18n;
export default function trans(key, params, defaultValue) {
    if (i18n === undefined) {
        console.error("I18n instance is not set, did you call `initTranslator`?");
        return defaultValueOrKey(key, defaultValue);
    }
    if (!i18n.global.te(key)) {
        return defaultValueOrKey(key, defaultValue);
    }
    // @ts-ignore
    return i18n.global.t(key, params);
}
;
/**
 * Try one translation key after another and pick the first one that has a
 * translation. If none of them has a translation, show the first key and log
 * all of them. The signature requires at least two keys.
 */
export function tt(t0, t1, ...ts) {
    if (i18n === undefined) {
        console.error("I18n instance is not set, did you call `initTranslator`?");
    }
    else {
        for (let key of [t0, t1, ...ts]) {
            if (i18n.global.te(key)) {
                // @ts-ignore
                return i18n.global.t(key);
            }
        }
    }
    console.warn("$tt: none of the provided translation keys have a translation: ", [t0, t1, ...ts]);
    return t0;
}
export function transExists(key) {
    if (i18n === undefined) {
        return false;
    }
    return i18n.global.te(key);
}
function defaultValueOrKey(key, defaultValue) {
    if (!defaultValue) {
        return key;
    }
    if (typeof defaultValue === 'string') {
        return defaultValue;
    }
    return defaultValue();
}
export function initTranslator(app, initialLocale, router, initialMessages) {
    const options = {
        messageResolver: simpleMessageResolver,
        locale: initialLocale,
        allowComposition: true,
        messages: {}
    };
    if (initialMessages && options.messages) {
        // cast to `any` because I don't know how to get this to type-check
        options.messages[initialLocale] = initialMessages;
    }
    const i18nInstance = createI18n(options);
    i18n = i18nInstance;
    if (router) {
        installNavigationGuard(i18nInstance, router);
    }
    // ATTENTION! Changes and additions to globalProperties functions need to be reflected in common/ui/ui/src/ui.d.ts!
    app.config.globalProperties.$trans = trans;
    app.config.globalProperties.$tt = tt;
    app.use(i18nInstance);
}
function simpleMessageResolver(obj, path) {
    const msg = obj[path];
    if (msg) {
        // replace angular translation syntax with vue i18n syntax
        return msg.replace(/{{(.+?)}}/g, '{$1}');
    }
    else {
        return null;
    }
}
export function setI18nLanguage(i18n, locale) {
    i18n.global.locale = locale;
    /**
     * NOTE:
     * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
     * The following is an example for axios.
     *
     * axios.defaults.headers.common['Accept-Language'] = locale
     */
    // document.querySelector('html').setAttribute('lang', locale)
}
export function installNavigationGuard(i18n, router) {
    router.beforeEach(async (to, from, next) => {
        // const paramsLocale = to.params.locale
        //
        // // use locale if paramsLocale is not in SUPPORT_LOCALES
        // if (!SUPPORT_LOCALES.includes(paramsLocale)) {
        //     return next(`/${locale}`)
        // }
        const locale = env.locale;
        // load locale messages
        if (!i18n.global.availableLocales.includes(locale)) {
            await loadLocaleMessages(i18n, locale);
        }
        // set i18n language
        setI18nLanguage(i18n, locale);
        return next();
    });
}
async function loadLocaleMessages(i18n, locale) {
    const version = env.version;
    const messages = await axios.get(env.translations.baseUrl + '/v' + version, { params: { lang: locale } }).then(response => {
        return response.data;
    }).catch((error) => {
        log('Failed to load translation messages: ' + error);
    });
    // set locale and locale message
    i18n.global.setLocaleMessage(locale, messages);
    // todo: this is part of the documentation but it doesn't seem to be needed
    // return nextTick()
}
