import events from "@astina/paloma-common-ui/src/services/EventBus";
events.$on('command.delete_order_draft.success', (cartId) => {
    if (cartId === env.orders.activeDraft) {
        env.orders.activeDraft = null;
    }
});
events.$on('command.purchase_order.success', (order) => {
    if (order.id === env.orders.activeDraft) {
        env.orders.activeDraft = null;
    }
});
events.$on('command.delete_watchlist.success', (watchlistId) => {
    if (watchlistId === env.orders.activeWatchlist) {
        env.orders.activeWatchlist = null;
    }
});
const defaults = {
    path: '/portal',
    locale: 'de',
    version: '0.0.0',
    app: {
        name: 'Paloma Portal',
        title: 'Paloma Portal',
    },
    security: {
        anonymousAccessEnabled: false,
    },
    user: {
        authenticated: false,
        username: 'anonymous',
        emailAddress: 'unknown',
        gender: 'unknown',
        permissions: [],
    },
    customer: {
        watchlistShareUrlPattern: '',
    },
    orders: {
        activeDraft: null,
        activeWatchlist: null,
    },
    catalog: {
        categories: {},
    },
    files: {
        baseUrl: '/files',
    },
    translations: {
        baseUrl: '../messages',
        locales: ['de'],
    },
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    importExportProfileApplication: 'data', // deprecated
    importProfileApplication: 'data',
    export: ['data'],
};
// @ts-ignore
const env = { ...defaults, ...window._ENV };
export default env;
