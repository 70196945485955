import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import translate from '@astina/paloma-common-ui/src/services/Translator';
import ValidationUtils from "@astina/paloma-common-ui/src/services/ValidationUtils";
export function updateWatchlistItem(watchlistId, item) {
    return new UpdateWatchlistItem(watchlistId, item).enqueue();
}
export default class UpdateWatchlistItem extends AbstractCommand {
    item;
    watchlistId;
    constructor(watchlistId, item) {
        super();
        this.watchlistId = watchlistId;
        this.item = item;
    }
    execute() {
        return paloma
            .execute(this.name(), {
            watchlistId: this.watchlistId,
            item: this.item
        })
            .then(watchlist => {
            return watchlist;
        })
            .catch((error) => {
            try {
                const validation = ValidationUtils.createValidation(error);
                ValidationUtils.alertAll(validation);
            }
            catch (e) {
                alerts.error(translate(`command.${this.name()}.error`));
            }
            throw error;
        });
    }
    name() {
        return 'update_watchlist_item';
    }
    icon() {
        return 'watchlist';
    }
    requiresPermission() {
        return 'PORTAL_WATCHLISTS_MANAGE';
    }
}
