import paloma from './PalomaBackend';
import events from './EventBus';
import log from './Logger';
import env from './AppEnv';
const CHECK_INTERVAL = 30000;
let interval = null;
export const EVENT_AUTH_EXPIRED = 'auth.expired';
export default {
    init() {
        if (!interval) {
            log('Starting authentication checks');
            interval = window.setInterval(() => {
                log('Checking authentication status');
                const authenticated = env.user.authenticated;
                paloma.checkAuthenticationStatus()
                    .catch(() => {
                    if (authenticated) {
                        events.$emit(EVENT_AUTH_EXPIRED);
                    }
                });
            }, CHECK_INTERVAL);
        }
    }
};
