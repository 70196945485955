import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import alerts from "@astina/paloma-common-ui/src/services/Alerts";
import trans from '@astina/paloma-common-ui/src/services/Translator';
export function saveWatchlistSharingSettings(watchlistId, settings) {
    return new SaveWatchlistSharingSettings(watchlistId, settings)
        .enqueue();
}
export default class SaveWatchlistSharingSettings extends AbstractCommand {
    watchlistId;
    settings;
    constructor(watchlistId, settings) {
        super();
        this.watchlistId = watchlistId;
        this.settings = settings;
    }
    execute() {
        return paloma
            .execute(this.name(), {
            watchlistId: this.watchlistId,
            settings: this.settings,
        })
            .catch((e) => {
            alerts.error(trans(`command.${this.name()}.error`));
            throw e;
        });
    }
    requiresPermission() {
        return 'PORTAL_WATCHLISTS_MANAGE';
    }
    name() {
        return 'save_watchlist_sharing_settings';
    }
    icon() {
        return 'share';
    }
}
