import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import trans from '@astina/paloma-common-ui/src/services/Translator';
import orderUtils from "@astina/paloma-shop-common-ui/src/services/OrderUtils";
export function repeatOrder(orderNumber) {
    return new RepeatOrder(orderNumber)
        .enqueue();
}
export default class RepeatOrder extends AbstractCommand {
    orderNumber;
    constructor(orderNumber) {
        super();
        this.orderNumber = orderNumber;
    }
    execute() {
        alerts.info(trans(`command.${this.name()}.started`));
        return paloma
            .execute(this.name(), {
            orderNumber: this.orderNumber,
        })
            .then((draft) => {
            orderUtils.handleCartModifications(draft);
            // if (draft.modifications) {
            //     draft.modifications.forEach(modification => {
            //         alerts.warning(trans(`command.${this.name()}.item_unavailable`, modification.params));
            //     });
            // }
            return draft;
        });
    }
    needsConfirmation() {
        return true;
    }
    requiresPermission() {
        return 'PORTAL_ORDERS_CREATE';
    }
    name() {
        return 'repeat_order';
    }
    icon() {
        return 'redo';
    }
}
