import env from '../services/AppEnv';
import paloma from "../services/PalomaBackend";
import axios from 'axios';
const available = !!(env.docs && env.docs.baseUrl);
function createHttpClient() {
    return axios.create({
        baseURL: env.docs.baseUrl + '/api/',
    });
}
const http = available ? createHttpClient() : null;
class HelpRepository {
    get(code) {
        if (!available) {
            return Promise.reject();
        }
        return http.get('help', { params: { codes: code, locale: env.locale } })
            .then((response) => response.data[0]);
    }
    getContextHelp(context) {
        if (context.length === 0) {
            return Promise.resolve(null);
        }
        return paloma.http.get('docs/context/help', { params: {
                context: toContextStr(context),
                locale: env.locale,
            } })
            .then((response) => response.data);
    }
    saveContextComment(context, comment) {
        return paloma.post('docs/context/comments', {
            context: context,
            comment: comment,
        });
    }
    deleteContextComment(id) {
        return paloma.delete(`docs/context/comments/${id}`);
    }
    countContextComments(context) {
        return paloma.http.get('docs/context/comments/count', { params: {
                context: toContextStr(context),
            } })
            .then((response) => response.data);
    }
    isAvailable() {
        return available;
    }
}
export function contextParamsStr(params) {
    let paramsStr = '';
    for (let key in params) {
        paramsStr += ':' + key + ':' + params[key];
    }
    return paramsStr;
}
function toContextStr(context) {
    return context
        .map(item => item.route + contextParamsStr(item.params))
        .reduce((i1, i2) => i1 + ',' + i2);
}
export function createHelpContext(route) {
    return (route.matched || [])
        .map(r => {
        const routeParams = {};
        for (let paramsKey in route.params) {
            if (r.path.match(new RegExp('/:' + paramsKey + '(?:/|$)'))) {
                routeParams[paramsKey] = route.params[paramsKey];
            }
        }
        return {
            route: r.name + '',
            params: routeParams,
        };
    });
}
export default new HelpRepository();
