import palomaBackend from "./PalomaBackend";
import env from './AppEnv';
import alerts, { handleHttpError } from "./Alerts";
const http = palomaBackend.http;
let listAbortController = null;
class ProcessReportRepository {
    list(searchRequest, page, attributes) {
        listAbortController && listAbortController.abort();
        listAbortController = new AbortController();
        return http
            .post('logs/process-reports/grid/items', searchRequest, {
            params: {
                locale: env.locale,
                page: page.number,
                size: page.size,
                sort: page.sort ? (page.sort + (page.order ? ',' + page.order : null)) : 'times.created,desc',
                attributes: attributes.join(','),
            },
            signal: listAbortController.signal,
        })
            .then((response) => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    get(handler, process, id) {
        return http
            .get(`logs/process-reports/${handler}/${process}/${id}`)
            .then((response) => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    delete(handler, process, id) {
        return http
            .delete(`logs/process-reports/${handler}/${process}/${id}`)
            .catch(handleHttpError);
    }
    refresh(handler, process, id) {
        return http
            .post(`logs/process-reports/${handler}/${process}/${id}/refresh`)
            .catch(handleHttpError);
    }
    executeBulkOperation(operation, params) {
        const data = {
            searchRequest: params.searchRequest,
            selected: params.selected(),
            excluded: params.excluded(),
        };
        return http
            .post(`logs/process-reports/bulk-operation/${operation}`, data)
            .then(response => response.data)
            .catch((error) => {
            if (error.response && error.response.data && error.response.data.message) {
                alerts.error(error.response.data.message);
                return;
            }
            throw error;
        })
            .catch(handleHttpError);
    }
    getGridConfig(capabilities) {
        return http
            .get('logs/process-reports/grid/config', {
            params: {
                locale: env.locale,
            }
        })
            .then((response) => {
            const grid = response.data;
            grid.capabilities = capabilities || grid.capabilities;
            return grid;
        })
            .catch(handleHttpError);
    }
    getGridItemsSource() {
        return {
            get: this.list
        };
    }
    getGridStatisticsSource() {
        return {
            get(searchRequest, statistics) {
                return http.post('logs/process-reports/grid/stats', searchRequest, {
                    params: { items: statistics }
                })
                    .then((response) => { return response.data; })
                    .catch(handleHttpError);
            }
        };
    }
}
export default new ProcessReportRepository();
