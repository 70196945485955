import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
export function createDeliveryLocation(deliveryLocation) {
    return new CreateDeliveryLocation(deliveryLocation)
        .enqueue();
}
export default class CreateDeliveryLocation extends AbstractCommand {
    deliveryLocation;
    constructor(deliveryLocation) {
        super();
        this.deliveryLocation = deliveryLocation;
    }
    execute() {
        return paloma
            .execute(this.name(), this.deliveryLocation);
    }
    name() {
        return 'create_delivery_locations';
    }
    requiresPermission() {
        return 'PORTAL_DELIVERY_LOCATIONS_MANAGE';
    }
}
