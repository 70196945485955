import { onBeforeUnmount, watchEffect } from 'vue';
import log from "../services/Logger";
/**
 * @param el the element to bind the "clickaway" functionality to
 * @param callback function to call if a click event happens outside the element
 */
export function useClickaway(el, callback) {
    watchEffect(() => {
        if (el.value) {
            const theElement = rootElement();
            if (theElement) {
                addClickaway(theElement);
            }
        }
    });
    onBeforeUnmount(() => {
        document.documentElement.removeEventListener('click', onAnyClick, false);
    });
    function addClickaway(element) {
        if (!element.hasAttribute("clickaway")) {
            element.setAttribute("clickaway", "enabled");
            log("clickaway enabled", element);
            document.documentElement.addEventListener('click', onAnyClick, false);
        }
        else {
            // never happens?
            log("clickaway already enabled");
        }
    }
    function onAnyClick(ev) {
        const path = ev.composedPath ? ev.composedPath() : undefined;
        const element = rootElement();
        if (element && ev.target instanceof Element) {
            if ((path ? path.indexOf(element) < 0 : !element.contains(ev.target))) {
                callback();
            }
        }
    }
    function rootElement() {
        const v = el.value;
        if (v instanceof HTMLElement) {
            return v;
        }
        else if (v !== null && '$el' in v) {
            return v.$el;
        }
        console.error("Failed to select HTMLElement", v);
        return null;
    }
}
