import translate from '../../services/Translator';
import { get } from 'lodash';
const EMAIL_VALIDATION_REGEXP = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
function validateField(field, data) {
    const options = field.options || {};
    const value = get(data, field.name);
    const errors = [];
    if (options.required && (value === undefined || value === null)) {
        errors.push(translate('form_field_required'));
    }
    if (value && field.type === 'email' && !isEmailAddress(value)) {
        errors.push(translate('form_field_email_address_invalid'));
    }
    if (value != null && options.min !== undefined && options.min !== null && value < options.min) {
        errors.push(translate('form_field_value_too_low', { min: options.min }));
    }
    if (value && options.max !== undefined && options.max !== null && value > options.max) {
        errors.push(translate('form_field_value_too_high', { max: options.max }));
    }
    if (value != null && options.minLength !== undefined && options.minLength !== null && value.length < options.minLength) {
        errors.push(translate('form_field_value_too_short', { min_length: options.minLength }));
    }
    if (value && options.maxLength !== undefined && options.maxLength !== null && value.length > options.maxLength) {
        errors.push(translate('form_field_value_too_long', { max_length: options.maxLength }));
    }
    return errors;
}
function isEmailAddress(value) {
    return value && EMAIL_VALIDATION_REGEXP.test(value);
}
export default {
    validate(fields, data, fieldPrefix) {
        const fieldValidations = {};
        fields.forEach(field => {
            const fieldPath = (fieldPrefix && fieldPrefix + '.') + field.name;
            fieldValidations[fieldPath] = validateField(field, data);
        });
        return {
            errors: [],
            fields: fieldValidations,
        };
    },
    isValid(validation) {
        return validation.errors.length === 0
            && Object.getOwnPropertyNames(validation.fields).filter(field => validation.fields[field].length > 0)
                .length === 0;
    },
    extractNestedValidation(validation, path) {
        const fields = {};
        Object.keys(validation.fields)
            .filter(field => field.startsWith(path + '.'))
            .forEach(field => {
            fields[field.replace(path + '.', '')] = validation.fields[field];
        });
        return {
            errors: [],
            fields: fields,
        };
    }
};
