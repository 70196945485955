import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import alerts from "@astina/paloma-common-ui/src/services/Alerts";
import translate from "@astina/paloma-common-ui/src/services/Translator";
import ValidationUtils from "@astina/paloma-common-ui/src/services/ValidationUtils";
import events from "@astina/paloma-common-ui/src/services/EventBus";
export default class PurchaseWatchlistOrder extends AbstractCommand {
    token;
    items;
    deliveryDate = null;
    constructor(token, items) {
        super();
        this.token = token;
        this.items = items;
    }
    execute() {
        return paloma
            .execute(this.name(), {
            token: this.token,
            items: this.items,
            deliveryDate: this.deliveryDate,
        })
            .catch((error) => {
            try {
                let validation = ValidationUtils.createValidation(error);
                // if (validation.fields) {
                //     const fields: FormValidationFields = {};
                //     for (let property in validation.fields) {
                //         // validation errors for items will be displayed separately
                //         if (property.indexOf('[') === -1) {
                //             fields[property] = validation.fields[property];
                //         }
                //     }
                //     validation.fields = fields;
                // }
                if (error && error.errors) {
                    const dateErrors = error.errors.filter((err) => err.code === 'watchlist_invalid_item_shipping_target_date' || err.code === 'invalid_shipping_target_date');
                    if (dateErrors.length > 0) {
                        const nextPossibleDeliveryDate = error.errors.filter((err) => err.property === 'nextPossibleDeliveryDay');
                        error.errors = error.errors.filter((err) => err.property !== 'nextPossibleDeliveryDay'
                            && err.code !== 'watchlist_invalid_item_shipping_target_date'
                            && err.code !== 'invalid_shipping_target_date');
                        validation = ValidationUtils.createValidation(error);
                        events.$emit('app.shipping-target-date-error', nextPossibleDeliveryDate.length > 0 ? nextPossibleDeliveryDate[0].message : null);
                        const dateErrorValidations = ValidationUtils.createValidation({ 'errors': dateErrors });
                        dateErrorValidations.errors.forEach((e) => alerts.warning('', (typeof e === 'string') ? e : e.message, { 'timeout': 10000 }));
                    }
                }
                ValidationUtils.alertAll(validation);
            }
            catch (e) {
                alerts.error(translate('cart.purchase.error'));
            }
            throw error;
        });
    }
    name() {
        return 'purchase_watchlist_order';
    }
}
