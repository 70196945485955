import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import env from '../services/AppEnv';
export function activateWatchlist(watchlistId) {
    return new ActivateWatchlist(watchlistId)
        .enqueue();
}
export default class ActivateWatchlist extends AbstractCommand {
    watchlistId;
    constructor(watchlistId) {
        super();
        this.watchlistId = watchlistId;
    }
    execute() {
        env.orders.activeWatchlist = this.watchlistId;
        return paloma
            .execute(this.name(), {
            watchlistId: this.watchlistId
        })
            .catch(error => {
            env.orders.activeWatchlist = null;
            return error;
        });
    }
    name() {
        return 'activate_watchlist';
    }
}
