import palomaBackend from "@astina/paloma-common-ui/src/services/PalomaBackend";
import env from '../services/AppEnv';
import { handleHttpError } from "@astina/paloma-common-ui/src/services/Alerts";
const http = palomaBackend.http;
class DeliveryRepository {
    list(searchRequest, page, attributes) {
        return http
            .post('deliveries/list_deliveries', searchRequest, {
            params: {
                locale: env.locale,
                page: page.number,
                size: page.size,
                sort: page.sort && (page.sort + (page.order ? ',' + page.order : null)),
                attributes: attributes.join(','),
            }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    listExpectedDeliveries(start, end) {
        return http
            .get('deliveries/list_expected_deliveries', {
            params: {
                locale: env.locale,
                start: start,
                end: end,
            }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    get(id) {
        return http
            .get('deliveries/get_delivery', {
            params: {
                id: id,
                locale: env.locale,
            }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    listShippingOptions() {
        return http
            .get('deliveries/list_shipping_options', {
            params: {
                locale: env.locale,
            }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getGridConfig() {
        return http
            .get('deliveries/get_grid_config', {
            params: { locale: env.locale }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getGridItemsSource() {
        return {
            get: this.list
        };
    }
}
export default new DeliveryRepository();
