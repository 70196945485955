import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import orderUtils from "@astina/paloma-shop-common-ui/src/services/OrderUtils";
export function setCartDeliveryScheme(cartId, scheme) {
    return new SetCartDeliveryScheme(cartId, scheme)
        .enqueue();
}
export default class SetCartDeliveryScheme extends AbstractCommand {
    cartId;
    scheme;
    constructor(cartId, scheme) {
        super();
        this.cartId = cartId;
        this.scheme = scheme;
    }
    execute() {
        return paloma
            .execute(this.name(), {
            cartId: this.cartId,
            deliveryScheme: {
                name: this.scheme,
            },
        })
            .then((orderDraft) => {
            orderUtils.handleCartModifications(orderDraft);
            return orderDraft;
        });
    }
    name() {
        return 'set_cart_delivery_scheme';
    }
    requiresPermission() {
        return 'PORTAL_ORDERS_CREATE';
    }
}
