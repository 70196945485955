export default class AvailabilityService {
    static getUnavailabilityReasons(config) {
        return config.allowedUnavailabilityReasons.map(r => r.code);
    }
    static isUnavailableAndNoAllowedReason(variant, config) {
        return !variant.availability.available && AvailabilityService.anyReasonNotAllowed(variant, config);
    }
    static isUnavailableAndAllowedReason(variant, config) {
        return !variant.availability.available && !this.anyReasonNotAllowed(variant, config);
    }
    static anyReasonNotAllowed(variant, config) {
        let allowedReasons = config.allowedUnavailabilityReasons ?
            config.allowedUnavailabilityReasons.map(r => r.code) : [];
        let reasons = variant.availability.unavailabilityReasons;
        if (!reasons || reasons.length == 0) {
            return true;
        }
        return !reasons.every(reason => allowedReasons.indexOf(reason) > -1);
    }
}
