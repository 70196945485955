import trans from "../../services/Translator";
import log from "../../services/Logger";
// Global event listener for browser navigation (as opposed to vue-router navigation)
window.addEventListener('beforeunload', (event) => {
    if (hasDirtyForms()) {
        event.preventDefault();
        return event.returnValue = trans('unsaved_changes_warning');
    }
});
// Called in RouterUtils by vue-router navigation guard
function confirmDirtyForms() {
    if (!hasDirtyForms()) {
        return true;
    }
    if (window.confirm(trans('unsaved_changes_warning'))) {
        clearDirtyForms();
        return true;
    }
    return false;
}
function hasDirtyForms() {
    return FORMS.dirty.length > 0;
}
function clearDirtyForms() {
    FORMS.dirty.length = 0;
}
const FORMS = {
    dirty: []
};
let fieldIdSeq = 0;
export default {
    isSelectionField(field, fieldControl) {
        return (fieldControl === 'choice' && field.options && !field.options.expanded) || field.type === 'entity';
    },
    registerDirtyForm(form) {
        if (FORMS.dirty.indexOf(form) === -1) {
            FORMS.dirty.push(form);
            log('Registered dirty form', form.name);
        }
    },
    clearDirtyForm(form) {
        FORMS.dirty.splice(FORMS.dirty.indexOf(form), 1);
        log('Cleared dirty form', form.name);
    },
    isDirtyForm(form) {
        return FORMS.dirty.indexOf(form) > -1;
    },
    clearDirtyForms: clearDirtyForms,
    hasDirtyForms: hasDirtyForms,
    confirmDirtyForms: confirmDirtyForms,
    fieldIdSeq: fieldIdSeq,
};
