import palomaBackend from "./PalomaBackend";
import env from "./AppEnv";
const http = palomaBackend.http;
class NotificationJournalRepository {
    listDeliveries(searchRequest, page, attributes) {
        return http.post("notifications/deliveries/items", searchRequest, {
            params: {
                locale: env.locale,
                page: page.number,
                size: page.size,
                sort: page.sort && (page.sort + (page.order ? ',' + page.order : null)),
                attributes: attributes.join(','),
            }
        })
            .then(response => response.data);
    }
    getDeliveryGridConfig() {
        return http.get("notifications/deliveries/grid")
            .then(response => response.data);
    }
    getDeliveryGridItemsSource() {
        return {
            get: this.listDeliveries
        };
    }
}
export default new NotificationJournalRepository();
