import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import paloma from '@astina/paloma-common-ui/src/services/PalomaBackend';
import alerts from "@astina/paloma-common-ui/src/services/Alerts";
import trans from '@astina/paloma-common-ui/src/services/Translator';
export default class ResetWatchlistAccessCode extends AbstractCommand {
    watchlistId;
    constructor(watchlistId) {
        super();
        this.watchlistId = watchlistId;
    }
    execute() {
        return paloma
            .execute(this.name(), { watchlistId: this.watchlistId })
            .then((watchlist) => {
            alerts.success(trans(`command.${this.name()}.success`, { name: watchlist.name }));
            return watchlist;
        })
            .catch((e) => {
            alerts.error(trans(`command.${this.name()}.error`));
            throw e;
        });
    }
    name() {
        return "reset_watchlist_access_code";
    }
    requiresPermission() {
        return 'PORTAL_WATCHLISTS_MANAGE';
    }
    needsConfirmation() {
        return true;
    }
    isDangerous() {
        return true;
    }
    icon() {
        return 'redo';
    }
}
