import env from "../services/AppEnv";
import paloma from "./PalomaBackend";
import { handleHttpError } from "./Alerts";
const http = paloma.http;
class DataTransferRepository {
    list(searchRequest, page, attributes) {
        return http.post('logs/data-transfer/grid/items', searchRequest, {
            params: {
                locale: env.locale,
                page: page.number,
                size: page.size,
                sort: page.sort && (page.sort + (page.order ? ',' + page.order : null)),
                attributes: attributes.join(','),
            }
        })
            .then((response) => response.data)
            .catch(handleHttpError);
    }
    get(id) {
        return http.get(`logs/data-transfer/${id}`)
            .then((response) => response.data)
            .catch(handleHttpError);
    }
    getIntegrationStats(integrations, mode, abortController) {
        return http
            .get(`logs/data-transfer/integration-stats`, {
            params: { integrations: integrations.join(','), mode: mode },
            signal: abortController && abortController.signal,
        })
            .then((response) => response.data);
    }
    getGridConfig(capabilities) {
        return http.get('logs/data-transfer/grid/config', { params: { locale: env.locale } })
            .then((response) => {
            const grid = response.data;
            grid.capabilities = capabilities;
            return grid;
        })
            .catch(handleHttpError);
    }
    getGridItemsSource() {
        return {
            get: this.list,
        };
    }
    getGridStatisticsSource() {
        return {
            get(searchRequest, statistics) {
                return http.post('logs/data-transfer/grid/stats', searchRequest, {
                    params: { items: statistics }
                })
                    .then((response) => { return response.data; })
                    .catch(handleHttpError);
            }
        };
    }
}
export default new DataTransferRepository();
