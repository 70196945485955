import serviceLocator from "../services/ServiceLocator";
import userSettings from "../services/UserSettings";
import log from "../services/Logger";
class LocalStorageSearchRepository {
    save(collectionId, searchRequest) {
        return this.list(collectionId)
            .then(searchRequests => {
            if (searchRequest.id) {
                const existing = searchRequests.find(req => req.id === searchRequest.id);
                if (existing) {
                    existing.name = searchRequest.name;
                    existing.searchRequest = searchRequest.searchRequest;
                    log('Updated saved search request id=' + searchRequest.id);
                }
            }
            else {
                searchRequest.id = collectionId + Math.round(Math.random() * 10000000);
                searchRequests.push(searchRequest);
                log('Added saved search request id=' + searchRequest.id);
            }
            this.getSettings(collectionId).set(searchRequests);
        });
    }
    list(collectionId) {
        return Promise.resolve(this.getSettings(collectionId).get([]));
    }
    delete(collectionId, savedSearchRequestId) {
        return this.list(collectionId)
            .then(searchRequests => {
            const existing = searchRequests.find(req => req.id === savedSearchRequestId);
            if (existing) {
                searchRequests.splice(searchRequests.indexOf(existing), 1);
                this.getSettings(collectionId).set(searchRequests);
            }
        });
    }
    getSettings(collectionId) {
        return userSettings('paloma-ui.search-request.' + collectionId);
    }
}
class PalomaUserSettingsSearchRepository {
    settingsService;
    constructor(settingsService) {
        this.settingsService = settingsService;
    }
    save(collectionId, searchRequest) {
        return this.list(collectionId)
            .then(searchRequests => {
            if (searchRequest.id) {
                const existing = searchRequests.find(req => req.id === searchRequest.id);
                if (existing) {
                    existing.name = searchRequest.name;
                    existing.searchRequest = searchRequest.searchRequest;
                    log('Updated saved search request id=' + searchRequest.id);
                }
            }
            else {
                searchRequest.id = collectionId + Math.round(Math.random() * 10000000);
                searchRequests.push(searchRequest);
                log('Added saved search request id=' + searchRequest.id);
            }
            return this.settingsService.set('paloma-ui.search-request', collectionId, {
                searchRequests: searchRequests,
            });
        });
    }
    list(collectionId) {
        return this.settingsService.get('paloma-ui.search-request', collectionId)
            // @ts-ignore
            .then((data) => {
            if (!data) {
                return [];
            }
            else {
                return data.searchRequests || [];
            }
        });
    }
    delete(collectionId, savedSearchRequestId) {
        return this.list(collectionId)
            .then(searchRequests => {
            const existing = searchRequests.find(req => req.id === savedSearchRequestId);
            if (existing) {
                searchRequests.splice(searchRequests.indexOf(existing), 1);
                return this.settingsService.set('paloma-ui.search-request', collectionId, {
                    searchRequests: searchRequests,
                });
            }
        });
    }
}
export default class SavedSearchService {
    collectionId;
    repository;
    constructor(collectionId) {
        this.collectionId = collectionId;
        this.repository = serviceLocator.has('paloma_user_settings')
            ? new PalomaUserSettingsSearchRepository(serviceLocator.get('paloma_user_settings'))
            : new LocalStorageSearchRepository();
    }
    save(name, searchRequest) {
        return this.repository.save(this.collectionId, { name: name, searchRequest: searchRequest });
    }
    list() {
        return this.repository.list(this.collectionId);
    }
    delete(savedSearchRequest) {
        if (!savedSearchRequest.id) {
            return Promise.reject();
        }
        return this.repository.delete(this.collectionId, savedSearchRequest.id);
    }
}
