import env from "../services/AppEnv";
import moment from "moment";
import numeral from "numeral";
export function palomaUriFilter(uri) {
    if (uri.indexOf('files://') === 0) {
        return uri.replace(/^files:\//, env.files.baseUrl);
    }
    if (uri.indexOf('files:') === 0) {
        return uri.replace(/^files:/, env.files.baseUrl + '/');
    }
    return uri;
}
export function isPalomaFilesUri(uri) {
    return !!(uri && uri.indexOf('files:') === 0);
}
export function numberFilter(value) {
    return numeral(value).format('0,0');
}
export function currencyFilter(value, currency) {
    return (currency ? currency + ' ' : '') + numeral(value).format('0,0.00');
}
export function bytesFilter(value) {
    return numeral(value).format('0b');
}
export function stripZeroesFilter(value, keep) {
    if (!value) {
        return value;
    }
    // "2.034300" => "0343"
    let decimalPart = value.match(/\D/) ? value.match(/\D(\d+?)0*$/)[1] : '';
    if (decimalPart === '0') {
        decimalPart = '';
    }
    const decimals = Math.max(keep === undefined ? 0 : keep, decimalPart.length);
    return parseFloat(value).toFixed(decimals);
}
export function dateFilter(date, format) {
    return moment(date).format(format || 'DD.MM.YYYY');
}
export function dateTimeFilter(date) {
    return moment(date).format('DD.MM.YYYY HH:mm');
}
export function dateTimeTzFilter(date) {
    return moment(date).format('DD.MM.YYYY HH:mm (Z)');
}
export function dateTimeSecFilter(date) {
    return moment(date).format('DD.MM.YYYY HH:mm:ss');
}
export function dateTimeMillisFilter(date) {
    return moment(date).format('DD.MM.YYYY HH:mm:ss.SSS');
}
export function dateTimeMillisTzFilter(date) {
    return moment(date).format('DD.MM.YYYY HH:mm:ss.SSS (Z)');
}
export function timeFilter(date) {
    return moment(date).format('HH:mm');
}
export function timeSecFilter(date) {
    return moment(date).format('HH:mm:ss');
}
export function timeMillisFilter(date) {
    return moment(date).format('HH:mm:ss.SSS');
}
export function fromNowFilter(date) {
    return moment(date).fromNow();
}
export function dataTypeFilter(value, dataType) {
    if (value === undefined || value === null) {
        return value;
    }
    switch (dataType) {
        case 'currency':
            // looks for a decimal number at the end of the string and calls
            //   stripZeroesFilter on it. Does nothing if no decimal can be
            //   found.
            const sValue = '' + value;
            const m = sValue.match(/\d*.\d+$/);
            if (m) {
                return sValue.replace(/\d*.\d+$/, stripZeroesFilter(m[0], 2));
            }
            break;
        case 'date':
            return dateFilter(value);
        case 'datetime':
            return dateTimeFilter(value);
    }
    if (typeof value === 'object') {
        return JSON.stringify(value);
    }
    return '' + value;
}
