const config = {
    cart: {
        path: 'orders',
    },
    catalog: {
        path: 'catalog',
    },
};
export const cart = config.cart;
export const catalog = config.catalog;
export default config;
