import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import repository from '../services/ShopImportProfileRepository';
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import translate from '@astina/paloma-common-ui/src/services/Translator';
export function saveShopImportProfile(profile) {
    return new SaveShopImportProfile(profile)
        .enqueue();
}
export default class SaveShopImportProfile extends AbstractCommand {
    profile;
    constructor(profile) {
        super();
        this.profile = profile;
    }
    execute() {
        return repository.save(this.profile)
            .then(profile => {
            alerts.success(translate('shop_import_profiles.save_success'));
            return profile;
        });
    }
    needsConfirmation() {
        return true;
    }
    name() {
        return 'save_shop_import_profile';
    }
    icon() {
        return 'save';
    }
    requiresPermission() {
        return 'PORTAL_INTEGRATIONS_IMPORT';
    }
}
