import palomaBackend from "@astina/paloma-common-ui/src/services/PalomaBackend";
import env from '@astina/paloma-common-ui/src/services/AppEnv';
import { handleHttpError } from "@astina/paloma-common-ui/src/services/Alerts";
import { cart as config } from "./RepositoryConfig";
const http = palomaBackend.http;
class CartRepository {
    listCarts() {
        return http
            .get(`${config.path}/list_carts`, {
            params: { locale: env.locale }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    listChannels() {
        return http
            .get(`${config.path}/list_channels`, {
            params: { locale: env.locale }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    // Note: channel is needed in cockpit-ui/orders but not in portal-ui
    getCart(cartId, channel) {
        return http
            .get(`${config.path}/get_cart`, {
            params: {
                id: cartId,
                channel: channel,
                locale: env.locale,
            }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
    getShippingOptions(cartId, channel) {
        return http
            .get(`${config.path}/get_delivery_options`, {
            params: {
                cartId: cartId,
                channel: channel,
                locale: env.locale,
            }
        })
            .then(response => {
            const data = response.data;
            const deliveryDayOptions = data.deliveryDayOptions
                .map((delivery) => {
                return {
                    // @ts-ignore
                    targetDate: new Date(delivery.targetDate),
                    targetSlot: delivery.targetSlot,
                    deliverable: delivery.deliverable,
                    // @ts-ignore
                    orderDateTime: new Date(delivery.orderDateTime),
                    deliverySchemes: delivery.deliverySchemes,
                };
            });
            return {
                // @ts-ignore
                validUntil: new Date(data.validUntil),
                deliveryDayOptions: deliveryDayOptions,
            };
        })
            .catch(handleHttpError);
    }
    listDeliveryLocations(cartId, channel) {
        return http
            .get(`${config.path}/list_delivery_locations`, {
            params: {
                cartId: cartId,
                channel: channel,
                locale: env.locale,
            }
        })
            .then(response => {
            return response.data;
        })
            .catch(handleHttpError);
    }
}
export default new CartRepository();
