import { AbstractCommand } from "@astina/paloma-common-ui/src/services/CommandQueue";
import { clone } from 'lodash';
import repository from '../services/ShopImportProfileRepository';
import alerts from '@astina/paloma-common-ui/src/services/Alerts';
import translate from '@astina/paloma-common-ui/src/services/Translator';
export function duplicateShopImportProfile(profileId) {
    return new DuplicateShopImportProfile(profileId)
        .enqueue();
}
export default class DuplicateShopImportProfile extends AbstractCommand {
    profileId;
    constructor(profileId) {
        super();
        this.profileId = profileId;
    }
    execute() {
        return repository
            .get(this.profileId)
            .then(profile => {
            const duplicate = clone(profile);
            duplicate.id = null;
            duplicate.inputUsername = null;
            duplicate.status = 'inactive';
            duplicate.name = `${profile.name} (copy)`;
            return repository.save(duplicate);
        })
            .then(profile => {
            alerts.success(translate('shop_import_profiles.duplicate_success'));
            return profile;
        });
    }
    name() {
        return 'duplicate_shop_import_profile';
    }
    icon() {
        return 'copy';
    }
    requiresPermission() {
        return 'PORTAL_INTEGRATIONS_IMPORT';
    }
}
