import config from '@astina/paloma-shop-portal-ui/src/services/PortalConfig';
import { isGranted } from '@astina/paloma-common-ui/src/services/AccessManager';
import InfoNavItems from "@/components/InfoNavItems.vue";
import WatchlistOrderNotificationSettings from "@/components/WatchlistOrderNotificationSettings.vue";
import ContactView from "./views/ContactView.vue";
import ImprintView from "./views/ImprintView.vue";
import { createPortalUiApp } from "@astina/paloma-shop-portal-ui/src/init";
config.headerInfoComponent = InfoNavItems;
config.customRoutes = [
    {
        name: 'contact',
        path: '/contact',
        component: ContactView,
    },
    {
        name: 'imprint',
        path: '/imprint',
        component: ImprintView,
    },
];
config.cartDeliveryDaysMaxWeeksAhead = 12;
config.cartDeliveryDaysVisibleWeeks = 2;
config.articleOrderingUnitSelection = 'deny';
config.watchlistsSharingEnabled = true;
const app = createPortalUiApp();
if (isGranted('PORTAL_NOTIFICATIONS_MANAGE')) {
    app.component('miga-watchlist-order-notification-settings', WatchlistOrderNotificationSettings);
    config.extensions.watchlistAccessToken = 'miga-watchlist-order-notification-settings';
}
app.mount('#app');
