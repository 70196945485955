import trans from '@astina/paloma-common-ui/src/services/Translator';
import palomaBackend from "@astina/paloma-common-ui/src/services/PalomaBackend";
import { handleHttpError } from "@astina/paloma-common-ui/src/services/Alerts";
import env from "../services/AppEnv";
const http = palomaBackend.http;
class ShopIntegrationsRepository {
    list(searchRequest, page) {
        return http
            .get('orders/shop/integrations', {
            params: {
                page: page.number,
                size: page.size,
                sort: page.sort || 'name,asc',
                locale: env.locale,
            }
        })
            .then((response) => {
            const data = response.data;
            const page = {
                content: data.content.map((integration) => {
                    return {
                        id: integration.name,
                        title: integration.label,
                        status: ShopIntegrationsRepository.getGridItemStatus(integration),
                        attributes: {
                            name: integration.name,
                            label: integration.label,
                            status: integration.status && integration.status.status,
                            statusMessage: integration.status && integration.status.statusMessage,
                            active: integration.active,
                            enabled: integration.enabled,
                            iconUrl: integration.iconUrl,
                            manualUrl: integration.manualUrl,
                        }
                    };
                }),
                number: data.number,
                size: data.size,
                totalElements: data.totalElements,
                totalPages: data.totalPages,
            };
            return page;
        })
            .catch(handleHttpError);
    }
    static getGridItemStatus(integration) {
        if (integration.status && integration.status.status === 'error') {
            return 'invalid';
        }
        return integration.enabled && integration.active ? 'neutral' : 'disabled';
    }
    get(name) {
        return http
            .get(`orders/shop/integrations/${name}`)
            .then(response => response.data)
            .catch(handleHttpError);
    }
    enable(name) {
        return http
            .post(`orders/shop/integrations/${name}/enable`)
            .catch(handleHttpError);
    }
    disable(name) {
        return http
            .post(`orders/shop/integrations/${name}/disable`)
            .catch(handleHttpError);
    }
    getGridConfig() {
        return Promise.resolve({
            name: 'shop-integrations',
            capabilities: {
                search: false,
                bulkOperations: false,
            },
            columns: [
                {
                    name: 'name',
                    label: trans('name'),
                    dataType: 'text',
                    visible: false,
                    sortable: false,
                    size: 'm'
                },
                {
                    name: 'label',
                    label: trans('shop_integration.label'),
                    dataType: 'text',
                    visible: true,
                    sortable: false,
                    size: 'm'
                },
                {
                    name: 'manualUrl',
                    label: trans('shop_integration.manual'),
                    dataType: 'text',
                    visible: false,
                    sortable: false,
                    size: 'm'
                },
                {
                    name: 'statusMessage',
                    label: trans('status'),
                    dataType: 'text',
                    visible: true,
                    sortable: false,
                    size: 's'
                },
            ],
            filters: [],
        });
    }
    getGridItemsSource() {
        return {
            get: this.list
        };
    }
}
export default new ShopIntegrationsRepository();
