<template>
    <div class="imprint">
        <div class="container">

            <h1>{{ $t('nav.imprint') }}</h1>

            <div v-if="$i18n.locale === 'fr'">
                <h4>Mérat AG</h4>

                <p>Murtenstrasse 121<br>
                    3008 Bern<br><a href="tel:+41585714545" target="_blank">+41 (0)58 571 45 45</a><br><a
                            href="mailto:bern@merat.ch">bern@merat.ch</a></p>

                <h4>Directeur de l’entreprise Mérat AG</h4>

                <p>Reto Maurer</p>

                <h4>Registre du commerce</h4>

                <p>Mérat AG est inscrite au registre du commerce du canton de Bern.</p>

                <h4>Numéro d’entreprise:</h4>

                <p>CHE-107.812.956</p>

                <h4>Renvois et liens</h4>

                <p>S’agissant de renvois directs ou indirects à des pages Internet de tiers («liens») indépendantes du
                    contrôle de l’auteur, toute responsabilité est conditionnée au fait que l’auteur a connaissance des
                    contenus et qu’il lui était techniquement et raisonnablement possible d’empêcher l’utilisation de
                    contenus répréhensibles. C’est pourquoi l’auteur déclare expressément qu’au moment de la mise en
                    place des liens, les pages liées correspondantes étaient exemptes de contenus illégaux. L’auteur n’a
                    aucune influence sur la présentation et les contenus actuels et futurs des pages liées. Il se
                    désolidarise par avance de tous les contenus de toutes les pages liées/visées modifiées
                    postérieurement à la création des liens. Cette mise au point concerne tant les liens et renvois
                    présentés à l’intérieur de l’offre Internet propre que les contributions de tiers dans les livres
                    d’or, forums de discussion et listes d’adresses. Dans le cas de contenus illégaux, erronés ou
                    incomplets et notamment en ce qui concerne les dommages découlant de l’utilisation ou la non
                    utilisation d’informations présentées de cette nature, seul le diffuseur de la page en question peut
                    être tenu responsable quant à ces contenus, ceci excluant la responsabilité de la personne renvoyant
                    par un lien à la publication en question.</p>

                <h4>Droit d’auteur et autres droits</h4>

                <p>L’ensemble du contenu du site est protégé par la loi. Sans l’autorisation préalable de Mérat AG, vous
                    n’êtes pas autorisé à utiliser le site ou des parties de celui-ci de quelque manière que ce soit,
                    notamment à reproduire, transmettre et modifier le site ou à l’utiliser à des fins commerciales ou
                    publiques, que ce soit en tout ou en partie, par voie électronique ou autre.</p>

                <h4>Exclusion de responsabilité</h4>

                <p>L’auteur décline toute responsabilité quant à l’actualité, l’exactitude, l’exhaustivité ou la qualité
                    des informations mises à disposition. Les mises en cause de responsabilité de l’auteur concernant
                    des dommages matériels ou incorporels découlant de l’utilisation ou de la non utilisation des
                    informations présentées ou de l’utilisation d’informations incorrectes et incomplètes sont
                    expressément exclues, sauf faute délibérée ou négligence patente incontestable de l’auteur. Toutes
                    les offres sont sans engagement et n’entraînent aucune obligation. L’auteur se réserve expressément
                    le droit de modifier, compléter, supprimer sans préavis une partie des pages ou la totalité de
                    l’offre, ou d’interrompre la publication à titre temporaire ou définitif.</p>

                <h4>Les photos des produits</h4>

                <p>Les photos des produits sur ce site web sont uniquement destinées à des fins d'illustration. Les
                    produits livrés peuvent différer des images en termes de couleur, de forme, de taille, de design ou
                    d'emballage. Nous nous efforçons de maintenir les images des produits aussi actuelles et précises
                    que possible, mais nous ne pouvons pas garantir leur conformité avec le produit livré.</p>
            </div>

            <div v-else-if="$i18n.locale === 'it'">
                <h4>Mérat AG</h4>

                <p>Murtenstrasse 121<br>
                    3008 Bern<br><a href="tel:+41585714545" target="_blank">+41 (0)58 571 45 45</a><br><a
                            href="mailto:bern@merat.ch">bern@merat.ch</a></p>

                <h4>Responsabile aziendale di Mérat AG</h4>

                <p>Reto Maurer</p>

                <h4>Ufficio del registro di commercio</h4>

                <p>Mérat AG è registrata presso l’Ufficio del registro di commercio del Canton Bern.</p>

                <h4>IDI</h4>

                <p>CHE-107.812.956</p>

                <h4>Rimandi e link</h4>

                <p>In caso di rimandi diretti o indiretti a siti Internet esterni («link») che si trovano al di fuori
                    dell’ambito di responsabilità dell’autore, un obbligo di garanzia entrerebbe in vigore
                    esclusivamente nel caso in cui l’autore ne conoscesse i contenuti e gli fosse tecnicamente possibile
                    e ragionevole impedire l’utilizzo in caso di contenuti illeciti. Pertanto l’autore dichiara
                    espressamente che, al momento dell’inserimento dei link, i relativi siti linkati erano privi di
                    contenuti illegali. L’autore non ha alcuna influenza sull’organizzazione attuale e futura e sui
                    contenuti dei siti linkati/collegati. Pertanto, con la presente dichiarazione si distanzia
                    espressamente da tutti i contenuti di tutti i siti linkati/collegati che sono stati modificati dopo
                    l’inserimento dei link. Ciò vale per tutti i link e i rimandi inseriti all’interno del proprio sito
                    Internet nonché per inserimenti di terzi nei guestbook, nei forum di discussione e nelle mailing
                    list configurati dall’autore. Per contenuti illegali, errati o incompleti e in particolare per danni
                    derivanti dall’utilizzo o dal mancato utilizzo di informazioni di tale tipo rappresentate è
                    unicamente responsabile il provider del sito a cui si è stati rimandati e non quello che rimanda
                    tramite link alla rispettiva pubblicazione.</p>

                <h4>Diritto d’autore e altri diritti</h4>

                <p>L’intero contenuto del sito web è legalmente tutelato. Senza previa autorizzazione da parte di Mérat
                    AG non siete autorizzati a utilizzare il sito web o parti di esso in alcun modo, in particolare a
                    riprodurre il sito web (interamente o parzialmente), a trasferirlo (con mezzi elettronici o di altro
                    tipo), a modificarlo o a utilizzarlo per uno scopo commerciale o pubblico.</p>

                <h4>Esclusione della responsabilità</h4>

                <p>L’autore non si assume alcuna garanzia in merito all’attualità, alla correttezza, alla completezza o
                    alla qualità delle informazioni messe a disposizione. Pretese di responsabilità nei confronti
                    dell’autore riferite a danni materiali o ideali causati dall’utilizzo o dal mancato utilizzo delle
                    informazioni rappresentate o dall’utilizzo di informazioni errate o incomplete sono in linea di
                    principio escluse, purché da parte dell’autore non vi sia colpa grave o dolo comprovato. Tutte le
                    offerte sono soggette a modifiche e non sono vincolanti. L’autore si riserva espressamente di
                    modificare, integrare o cancellare parti delle pagine o l’intera offerta senza doverlo comunicare in
                    separata sede oppure di cessare la pubblicazione temporaneamente o definitivamente.</p>

                <h4>Le immagini dei prodotti</h4>

                <p>Le immagini dei prodotti presenti su questo sito web sono solo a scopo illustrativo. I prodotti
                    consegnati possono variare per colore, forma, dimensioni, design o confezione dalle immagini. Ci
                    sforziamo di mantenere le immagini dei prodotti il più possibile aggiornate e accurate, ma non
                    possiamo garantire che corrispondano al prodotto fornito.</p>
            </div>

            <div v-else>
                <h4>Mérat AG</h4>

                <p>Murtenstrasse 121<br>
                    3008 Bern<br><a href="tel:+41585714545" target="_blank">+41 (0)58 571 45 45</a><br><a
                            href="mailto:bern@merat.ch">bern@merat.ch</a></p>

                <h4>Unternehmensleiter der Mérat AG</h4>

                <p>Reto Maurer</p>

                <h4>Handelsregisteramt</h4>

                <p>Die Mérat AG ist registriert beim Handelsregisteramt des Kantons Bern.</p>

                <h4>Firmennummer</h4>

                <p>CHE-107.812.956</p>

                <h4>Bankverbindung</h4>

                <p>Migros Bank AG<br>
                    8001 Zürich</p>

                <p><strong>IBAN: CH60 0840 1016 1962 0660 7</strong></p>

                <p>Mérat AG<br>
                    Murtenstrasse 121<br>
                    3008 Bern</p>

                <h4>Verweise und Links</h4>

                <p>Bei direkten oder indirekten Verweisen auf fremde Internetseiten («Links»), die ausserhalb des
                    Verantwortungsbereichs des Autors liegen, würde eine Haftungsverpflichtung ausschliesslich in dem
                    Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm technisch möglich
                    und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Der Autor erklärt
                    daher ausdrücklich, dass zum Zeitpunkt der Linksetzung die entsprechenden verlinkten Seiten frei von
                    illegalen Inhalten waren. Der Autor hat keinerlei Einfluss auf die aktuelle und zukünftige
                    Gestaltung und auf die Inhalte der gelinkten/verknüpften Seiten. Deshalb distanziert er sich hiermit
                    ausdrücklich von allen Inhalten aller gelinkten/verknüpften Seiten, die nach der Linksetzung
                    verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebots gesetzten
                    Links und Verweise sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern,
                    Diskussionsforen und Mailinglisten. Für illegale, fehlerhafte oder unvollständige Inhalte und
                    insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener
                    Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde; nicht
                    derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.</p>

                <h4>Urheberrecht und andere Rechte</h4>

                <p>Der gesamte Inhalt der Website ist rechtlich geschützt. Ohne vorgängige Genehmigung der Mérat AG sind
                    Sie nicht berechtigt, die Website oder Teile davon in irgendeiner Weise zu nutzen, insbesondere die
                    Website (ganz oder teilweise) wiederzugeben, (mit elektronischen oder anderen Mitteln) zu
                    übermitteln, zu verändern oder für einen kommerziellen oder öffentlichen Zweck zu nutzen.</p>

                <h4>Haftungsausschluss</h4>

                <p>Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität
                    der bereitgestellten Informationen. Haftungsansprüche gegen den Autor, welche sich auf Schäden
                    materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen
                    Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht
                    wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches
                    oder grob fahrlässiges Verschulden vorliegt. Alle Angebote sind freibleibend und unverbindlich. Der
                    Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte
                    Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig
                    einzustellen.</p>

                <h4>Produktbilder</h4>

                <p>Die Produktbilder auf dieser Webseite dienen nur der Veranschaulichung. Die gelieferten Produkte
                    können in Farbe, Form, Größe, Design oder Verpackung von den Bildern abweichen. Wir bemühen uns, die
                    Produktbilder so aktuell und genau wie möglich zu halten, können aber keine Garantie für die
                    Übereinstimmung mit dem gelieferten Produkt übernehmen.</p>
            </div>


            <p class="imprint__privacy-policy">
                <a :href="$t('privacy_policy.url')" target="_blank">
                    {{ $t('privacy_policy') }}
                </a>
            </p>

        </div>
    </div>
</template>

<style scoped lang="scss">

    @import "~@astina/ui-theme/src/css/theme";

    h1 {
        margin-bottom: 2rem;
    }

    .imprint {
        margin-top: 2rem;
    }

    .imprint__privacy-policy {
        text-decoration: underline;
        font-weight: $weight-text-medium;
    }

</style>
<script setup lang="ts">
</script>